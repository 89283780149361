import { FC } from 'react';

import styled from 'styled-components';

import { Markdown } from 'modules/Documents/Send/components/SuggestionsInfoModal/SuggestionsInfoModal';
import { suggestionsInfoModalMessages } from 'modules/Documents/Send/messages';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { underlineHover } from 'modules/Theme/mixins';
import { Box, CustomExpandable } from 'modules/Ui';
import { IconArrow } from 'modules/Ui/Icons';

const StyledCustomExpandable = styled(CustomExpandable)`
  .expandable__button {
    color: ${(props) => props.theme.colors.brand500};
    ${underlineHover}
  }

  .expandable__content {
    p {
      margin-bottom: 0;
    }

    ul {
      margin-top: 16px;
      margin-bottom: 24px;
      padding-bottom: 0;

      li {
        margin-bottom: 12px;
      }
    }
  }
`;

export const DialogExpandable: FC = () => {
  const { t } = useTranslations();
  return (
    <StyledCustomExpandable
      titleWidth="fit-content"
      id="carbon-copy-fields"
      title={
        <Box
          color="inherit"
          columnGap="8px"
          display="grid"
          fontSize={16}
          gridTemplateColumns="1fr 20px"
          lineHeight={19}
          marginBottom="24px"
          width="fit-content"
        >
          {t(suggestionsInfoModalMessages.summary)}
          <IconArrow size={20} color="inherit" className="expandable__icon" />
        </Box>
      }
      content={
        <Markdown>{t(suggestionsInfoModalMessages.contentReminders)}</Markdown>
      }
    />
  );
};
