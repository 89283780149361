import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

import { selectIsMobile } from 'modules/App/store/selectors';
import styled from 'modules/Theme/styled-components';
import { Box, Img, Text } from 'modules/Ui';

interface ZeroStateProps extends SpaceProps, LayoutProps, ColorProps {
  img: string;
  imgHeight?: number | string;
  imgMarginBottom?: string;
  imgWidth?: number | string;
  subtitle?: ReactNode | string;
  tag?: string;
  title?: string;
  titleMarginBottom?: string;
}

const Wrapper = styled(Box)<ZeroStateProps>`
  ${color}
  ${layout}
  ${space}
`;

const DataTableZeroState: React.FC<ZeroStateProps> = ({
  children,
  img,
  imgHeight,
  imgMarginBottom = '16px',
  imgWidth,
  subtitle,
  tag,
  title,
  titleMarginBottom = '8px',
  ...rest
}) => {
  const isMobile = useSelector(selectIsMobile);
  const height = imgHeight || (isMobile ? 101 : 120);
  return (
    <Wrapper
      boxSizing="border-box"
      fadeIn
      tag={tag || 'div'}
      textAlign="center"
      {...{ ...rest }}
    >
      <Img
        ariaHidden
        height={height}
        loading="lazy"
        marginBottom={imgMarginBottom}
        src={img}
        width={imgWidth}
      />
      {title && (
        <Text
          color="primary500"
          fontSize={{
            _: 16,
            sm: 18,
          }}
          lineHeight={{
            _: 22,
            sm: 28,
          }}
          marginBottom={titleMarginBottom}
        >
          {title}
        </Text>
      )}
      {subtitle && (
        <Text fontSize={14} lineHeight={22}>
          {subtitle}
        </Text>
      )}
      {children}
    </Wrapper>
  );
};

Wrapper.defaultProps = {
  margin: { sm: '0 auto' },
  padding: {
    _: '44px 20px 0',
    sm: '68px 0 0',
  },
  maxWidth: { sm: '639px' },
  minHeight: { sm: '450px' },
};

export default DataTableZeroState;
