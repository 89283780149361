import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useField } from 'formik';

import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Fieldset, FormikField, SelectFormikField, Text } from 'modules/Ui';

import { messages } from '../messages';

export const ReminderDaysForm: FC = () => {
  const isMobile = useSelector(selectIsMobile);
  const { t } = useTranslations();

  const [field] = useField('days');
  const daysMessage =
    field.value === 1 ? t(messages.sendDay) : t(messages.sendDays);
  return (
    <Fieldset
      backgroundColor={{ _: 'auxiliary100', sm: 'initial' }}
      columnGap="12px"
      display="grid"
      gridTemplateColumns={{
        _: '120px auto 1fr',
        sm: '64px auto 112px 1fr',
      }}
      hiddenLegend={isMobile}
      id="reminder-days-before-after"
      legend={t(messages.sendDate)}
      legendFontSize={16}
      legendFontWeight="initial"
      legendGridColumnEnd="5"
      legendGridColumnStart="1"
      padding={{ _: '16px', sm: 'initial' }}
      uppercase={false}
      marginBottom={{ _: '32px', sm: 'initial' }}
    >
      <FormikField
        id="days"
        label={t(messages.expiration)}
        labelHidden
        decimalDigits={0}
        onlyPositives
        required
        placeholder="0"
        textAlign="right"
        type="number"
        showErrorWhenPristine
      />
      <Text
        aria-hidden="true"
        color="gray800"
        width="32px"
        lineHeight={16}
        paddingTop="14px"
      >
        {daysMessage}
      </Text>
      <SelectFormikField
        id="when"
        label={t(messages.beforeAfterExpiration)}
        labelHide
        options={[
          { label: t(messages.beforeExpiration), value: 'before' },
          { label: t(messages.afterExpiration), value: 'after' },
        ]}
      />
      <Text
        aria-hidden="true"
        color="gray800"
        gridColumnEnd={{ _: '4', sm: 'initial' }}
        gridColumnStart={{ _: '1', sm: 'initial' }}
        lineHeight={16}
        paddingTop="14px"
      >
        {t(messages.ofTheExpiration)}
      </Text>
    </Fieldset>
  );
};
