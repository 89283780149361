import { Concept, LineType } from 'modules/Documents/models/document';

const processConcepts = (concepts: Concept[]) => {
  return concepts.map((concept) => ({
    ...concept,
    totalWithoutTaxes:
      ((concept?.subtotal || 0) * (100 - (concept?.discountPercentage || 0))) /
      100,
    total: concept.totalAmount,
    ...(!!concept.product && {
      productValues: {
        name: concept.name,
        sellingPrice: concept.unitPrice,
        taxKey: concept.taxKey,
        description: concept.description,
      },
    }),
    groupName: concept.groupName || '',
    id: Math.random().toString(),
    order: concept.order,
    type: LineType.CONCEPT,
  }));
};

export default processConcepts;
