import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  name: { id: 'gofre.User.EditRole.Form.name' },
  surname: { id: 'gofre.User.EditRole.Form.surname' },
  email: { id: 'gofre.common.label.email' },
  admin: { id: 'gofre.User.EditRole.Form.admin' },
  editor: { id: 'gofre.User.EditRole.Form.editor' },
  bookkeeper: { id: 'gofre.User.EditRole.Form.manager' },
  reader: { id: 'gofre.User.EditRole.Form.reader' },
  role: { id: 'gofre.User.EditRole.Form.role' },
  submit: { id: 'gofre.common.action.save' },
  cancel: { id: 'gofre.common.action.cancel' },
  removeUserFromBusiness: {
    id: 'gofre.User.EditRole.Form.removeUserFromBusiness',
  },
  namePlaceholder: { id: 'gofre.UserInvitation.Form.name' },
  surnamePlaceholder: { id: 'gofre.UserInvitation.Form.surname' },
  emailPlaceholder: { id: 'gofre.common.label.email' },
  rolePlaceholder: { id: 'gofre.UserInvitation.Form.role' },
});

export const roleInfo = defineMessages({
  admin: { id: 'gofre.RoleInfoPanel.info.admin' },
  editor: { id: 'gofre.RoleInfoPanel.info.editor' },
  reader: { id: 'gofre.RoleInfoPanel.info.reader' },
  bookkeeper: { id: 'gofre.RoleInfoPanel.info.manager' },
});

export const cancelInvitationDialog = defineMessages({
  title: { id: 'gofre.user.cancelInvitationDialog.title' },
  text: { id: 'gofre.user.cancelInvitationDialog.text' },
  button: { id: 'gofre.user.cancelInvitationDialog.button' },
});
