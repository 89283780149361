import { FC, ReactNode } from 'react';

import {
  space,
  SpaceProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
} from 'styled-system';

import styled from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';
import Link from 'modules/Ui/Link';

import { BannerContent } from './components';

interface ProductInfoBannerProps extends ColorProps, LayoutProps, SpaceProps {
  actionText?: string;
  children?: ReactNode;
  description: string;
  id?: string;
  image: string;
  onClick?: () => void;
  title?: string;
  to?: string;
  isNew?: boolean;
}

const ProductInfoWrapper = styled(Box)<ProductInfoBannerProps>`
  ${layout}
  ${space}
  ${color}
`;

export const ProductInfoBanner: FC<ProductInfoBannerProps> = ({
  actionText,
  children,
  description,
  id = 'product-info-banner',
  image,
  title,
  to = '',
  isNew,
  ...rest
}) => {
  return (
    <ProductInfoWrapper contentVisibility="auto" {...rest}>
      {to ? (
        <Link
          aria-label={`${title} ${description}`}
          data-testid={id}
          height="100%"
          display="block"
          to={to}
        >
          <BannerContent
            actionText={actionText}
            children={children}
            description={description}
            image={image}
            title={title}
            isNew={isNew}
          />
        </Link>
      ) : (
        <BannerContent
          actionText={actionText}
          children={children}
          description={description}
          image={image}
          title={title}
          isNew={isNew}
        />
      )}
    </ProductInfoWrapper>
  );
};

ProductInfoWrapper.defaultProps = {
  backgroundColor: 'brand400',
  color: 'gray0',
  height: { sm: '120px', lg: '250px' },
  width: { lg: 'calc(50% - 12px)' },
};
