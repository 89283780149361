import { FC, useCallback, useState } from 'react';
import { Visible } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import analyticsService from 'modules/Analytics/services/analyticsService';
import { useInvalidateAndUpdate } from 'modules/App/hooks/useInvalidateAndUpdate';
import { SUBSCRIPTION } from 'modules/App/queries/queries';
import { selectIsMobile } from 'modules/App/store/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { MODAL_WIDTH_SMALL, Dialog, useCloseModal } from 'modules/Modals';
import { createNotification } from 'modules/Notifications/actions';
import { NotificationType } from 'modules/Notifications/models';
import CardExpiryStripe from 'modules/Subscriptions/Create/components/PaymentData/Components/CardExpiryStripe';
import CardNumberStripe from 'modules/Subscriptions/Create/components/PaymentData/Components/CardNumberStripe';
import CvcStripe from 'modules/Subscriptions/Create/components/PaymentData/Components/CvcStripe';
import { LoadingStripeIndicator } from 'modules/Subscriptions/Create/components/PaymentData/LoadingStirpeIndicator';
import messages from 'modules/Subscriptions/Create/components/PaymentData/messages';
import PaymentCards from 'modules/Subscriptions/Create/components/PaymentData/PaymentCards';
import { payment as texts } from 'modules/Subscriptions/Create/messages';
import { useUpdatePaymentMethod } from 'modules/Subscriptions/hooks';
import { MSG_GENERIC_ERROR } from 'modules/Subscriptions/hooks/useCreateStripeSubscription/constants';
import theme from 'modules/Theme';
import { Box, Button, LoadingIndicator, Text, Warning } from 'modules/Ui';
import ErrorListener from 'modules/Ui/Formik/ErrorListener';
import { WarningType } from 'modules/Ui/Warning/Warning';

import { changeCardModal } from './messages';

interface Props {}

const styleOptions = {
  base: {
    color: theme.colors.gray800,
    fontFamily: 'Inter, Arial, sans-serif',
    '::placeholder': {
      color: theme.colors.gray400,
    },
  },
  invalid: {
    color: theme.colors.error,
  },
};

const getOptions = (text: string) => ({
  placeholder: text,
  style: { ...styleOptions },
});

const ChangeCardModal: FC<Props> = () => {
  const {
    t,
    formatHelpers: { br },
  } = useTranslations();
  const dispatch = useDispatch();
  const invalidateAndUpdate = useInvalidateAndUpdate();
  const [isUpdating, setIsUpdating] = useState(false);
  const [formError, setFormError] = useState('');
  const updatePaymentMethod = useUpdatePaymentMethod();
  const isMobile = useSelector(selectIsMobile);

  const closeModal = useCloseModal();

  const handleSubmit = useCallback(async () => {
    if (isUpdating) {
      return;
    }
    setFormError('');
    setIsUpdating(true);
    try {
      await updatePaymentMethod();
      invalidateAndUpdate({ invalidateQueries: [SUBSCRIPTION] });
      closeModal();
      dispatch(
        createNotification({
          type: NotificationType.SUCCESS,
          message: t(changeCardModal.success),
        })
      );
    } catch (error: any) {
      setFormError(error.message || t(texts[MSG_GENERIC_ERROR]));
      analyticsService.formError({
        formName: 'update-card',
        field: error.stripeError?.code || 'payment',
        error:
          error.stripeError?.message ||
          error.message ||
          t(texts[MSG_GENERIC_ERROR]),
      });
      const stripeCardNumber = document.getElementById('card-number-stripe');
      stripeCardNumber?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } finally {
      setIsUpdating(false);
    }
  }, []);

  return (
    <Dialog
      type="fullScreen"
      buttonPrimary={
        <Button
          data-testid="update-card"
          disabled={isUpdating}
          form="update-payment-method"
          type="submit"
          variant="secondary"
        >
          {t(changeCardModal.confirm)}
        </Button>
      }
      buttonSecondary={
        <Visible md lg xl xxl>
          <Button
            onClick={closeModal}
            variant="stroke"
            width={{ _: '100%', sm: 'initial' }}
          >
            {t(changeCardModal.cancel)}
          </Button>
        </Visible>
      }
      content={
        <>
          {isUpdating && (
            <LoadingIndicator
              full
              text={t(changeCardModal.updating, {
                br: () => (isMobile ? br : null),
              })}
              textSize={isMobile ? 'xl' : 'xxxl'}
            />
          )}
          {formError && (
            <Warning
              message={formError}
              marginTop={{ sm: '-8px' }}
              marginBottom={{ sm: '16px' }}
              type={WarningType.ERROR}
            />
          )}

          <Text marginBottom="12px">{t(changeCardModal.subtitle)}</Text>
          <Box
            backgroundColor="stateBg"
            padding="16px 32px"
            margin={{ _: '0 -16px', sm: '0 0 24px' }}
          >
            <PaymentCards />
            <LoadingStripeIndicator />
            <Formik
              enableReinitialize
              onSubmit={async (_values, actions) => {
                await handleSubmit();
                actions.setSubmitting(false);
              }}
              initialValues={{}}
            >
              {(formik) => (
                <form
                  onSubmit={formik.handleSubmit}
                  id="update-payment-method"
                  noValidate
                >
                  <ErrorListener />
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr"
                    columnGap={{ _: '16px', sm: '24px' }}
                  >
                    <CardNumberStripe
                      options={getOptions(t(messages.numberPlaceholder))}
                    />
                    <CardExpiryStripe
                      options={getOptions(t(messages.expiresPlaceholder))}
                    />
                    <CvcStripe options={getOptions(t(messages.cvv))} />
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </>
      }
      dialogWidth={{ md: MODAL_WIDTH_SMALL }}
      id="changeCard"
      onClose={closeModal}
      title={t(changeCardModal.title)}
    />
  );
};

export default ChangeCardModal;
