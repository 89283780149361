import { ReactNode } from 'react';

import { useField } from 'formik';
import {
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  GridAreaProps,
} from 'styled-system';

import MaskedField from 'modules/Ui/Form/MaskedField';

import useFocusHandler from './useFocusHandler';

interface MaskedFormikFieldProps
  extends LayoutProps,
    SpaceProps,
    FlexboxProps,
    GridAreaProps {
  autoTrim?: boolean;
  disabled?: boolean;
  helpText?: string;
  hidden?: boolean;
  gridColumnStart?: {} | string;
  icon?: string | ReactNode;
  id: string;
  info?: React.ReactNode;
  label: string;
  labelHidden?: boolean;
  mask: string;
  maskChar?: string | null;
  maxLength?: number;
  name?: string;
  onBlur?: (any: any) => void;
  placeholder?: string;
  placeholderChar?: string;
  required?: boolean;
  type?: string;
}

const MaskedFormikField = (props: MaskedFormikFieldProps) => {
  const {
    autoTrim,
    flex,
    helpText,
    hidden,
    icon,
    id,
    label,
    labelHidden,
    margin,
    maskChar,
    maxLength,
    name,
    onBlur,
    order,
    placeholder,
    placeholderChar,
    required,
    width,
    gridArea,
    gridColumnStart,
    ...rest
  } = props;

  const [field, meta] = useField(name || id);

  const onFocusHandler = useFocusHandler(name || id);

  return hidden ? null : (
    <MaskedField
      {...{
        flex,
        helpText,
        icon,
        id,
        label,
        labelHidden,
        margin,
        maskChar,
        maxLength,
        order,
        placeholder,
        placeholderChar,
        required,
        width,
        gridArea,
        gridColumnStart,
        ...rest,
      }}
      {...field}
      error={meta.touched && meta.error ? meta.error : undefined}
      name={name || id}
      type="text"
      autoComplete="off"
      onFocus={onFocusHandler}
    />
  );
};

export default MaskedFormikField;
