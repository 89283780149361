import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactElement,
} from 'react';
import { useHistory } from 'react-router';

import { UserPermissions } from 'modules/Auth/constants';
import useHasUserScope from 'modules/Auth/hooks/checkUsersPermissions';
import useElectronicDocumentProvider from 'modules/Business/hooks/useElectronicDocumentProvider';
import useBaiVatNumberErrors from 'modules/Documents/hooks/useBaiVatNumberErrors';
import {
  DocumentFilters,
  DocumentKind,
  DocumentStatus,
  DocumentTable,
  DocumentType,
  DigitalDocumentStatus,
  DocumentPaidStatus,
} from 'modules/Documents/models/document';
import { StatusInfo } from 'modules/Documents/models/statusInfo';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { ValidGenders } from 'modules/I18n/types/validGenders';
import breakpoints, { max, min } from 'modules/Theme/breakpoints';
import ellipsis from 'modules/Theme/mixins/ellipsis';
import styled from 'modules/Theme/styled-components';
import { Box, Spinner } from 'modules/Ui';
import DataTable from 'modules/Ui/DataTable/DataTable';
import {
  DataTableAction,
  DataTableColumn,
} from 'modules/Ui/DataTable/DataTable.models';
import DataTableFiltersWrapper from 'modules/Ui/DataTable/DataTableFiltersWrapper';

import {
  CustomDocumentBulkActionsProps,
  useDocumentBulkActions,
} from '../hooks/useDocumentBulkActions';
import useDocumentListConfig from '../hooks/useDocumentListConfig';
import useDocumentListDataTable from '../hooks/useDocumentListDataTable';
import { filter, zeroResults } from '../messages';
import { DocumentKindQueryParamsState } from '../types';
import DocumentsZeroState from './DocumentsZeroState';
import DocumentsFilterForm from './Filter/DocumentsFilterForm';
import TagList from './Filter/Tags/TagList';

interface DocumentListProps {
  allowSelectAll?: boolean;
  currentTab?: DocumentKind;
  className?: string;
  status?: DocumentStatus;
  filtersActive: boolean;
  closeFilters: () => void;
  setFiltersDisabled: (status: boolean) => void;
  updateQueryParams: (event: DocumentFilters) => void;
  queryParamsState: DocumentKindQueryParamsState;
  searchTerm: string;
  CustomZeroState?: ReactElement;
  customBulkActions?: (
    props: CustomDocumentBulkActionsProps
  ) => DataTableAction<DocumentTable>[];
  customHandleSelectionChange?: (newSelected: DocumentTable[]) => void;
  extraQuery?: string;
  excludeTestInvoices?: boolean;
  customOnSelect?: () => void;
  customColumns?: DataTableColumn<DocumentTable, keyof DocumentTable>[];
  preSelectedDocuments?: DocumentTable[];
}

const StyledDocumentsTable = styled(Box)`
  @media ${max(767)} {
    .data__table {
      &--code { grid-area: code;}
      &--fiscalName { grid-area: fiscalName;}
      &--issuedDate { grid-area: issuedDate;}
      &--total {grid-area: total;}
      &--status {grid-area: status; display: flex;}
      &--digitalDocument {display: none;}
      &--send {grid-area: send; justify-self: flex-end}
    }

    tbody tr {
      align-items: center;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "code issuedDate"
        "fiscalName total"
        "status send";
    }
  }

  .data__table--fiscalName {
    @media ${min(breakpoints.sm)} {
      ${ellipsis}
    }
  }
  .data__table--reference,
  .data__table--dueDate {
    @media ${max(1023)} {
      display: none;
    }
  }
`;

const DocumentList: FC<DocumentListProps> = ({
  allowSelectAll,
  currentTab = DocumentKind.NORMAL,
  className,
  status,
  filtersActive,
  closeFilters,
  setFiltersDisabled,
  updateQueryParams,
  queryParamsState,
  searchTerm,
  CustomZeroState,
  customBulkActions,
  customHandleSelectionChange,
  extraQuery,
  excludeTestInvoices,
  customOnSelect,
  customColumns,
  preSelectedDocuments,
}) => {
  const { t } = useTranslations();
  const history = useHistory();
  const { hasUserScope, isEditor } = useHasUserScope();
  const hasBaiVatNumberErrors = useBaiVatNumberErrors();
  const { hasElectronicProvider, canVoidDocument, canSendElectronicDocument } =
    useElectronicDocumentProvider();
  const isUserReader = hasUserScope(UserPermissions.SUBSCRIPTION_BASE);
  const [showBigLoading, setShowBigLoading] = useState(true);
  const [selectionInverted, setSelectionInverted] = useState(false);
  const [isSomeDraftSelected, setIsSomeDraftSelected] = useState(false);
  const [isSomeVoidSelected, setIsSomeVoidSelected] = useState(false);
  const [isSomeCorrectiveSelected, setIsSomeCorrectiveSelected] =
    useState(false);
  const [isSomeReceiptSelected, setIsSomeReceiptSelected] = useState(false);
  const [isSingleVoidableSelected, setIsSingleVoidableSelected] =
    useState(false);
  const [isSomePaidSelected, setIsSomePaidSelected] = useState(false);
  const [isSomeFreezedSelected, setIsSomeFreezedSelected] = useState(false);
  const [isSomeCorrectedSelected, setIsSomeCorrectedSelected] = useState(false);
  const [isSomeReplacedSelected, setIsSomeReplacedSelected] = useState(false);
  const [isSomeIsUploadFile, setIsSomeIsUploadFile] = useState(false);
  const [isSomeHasBaiVatNumberErrors, setIsSomeHasBaiVatNumberErrors] =
    useState(false);
  const [onlyTestInvoicesSelected, setOnlyTestInvoicesSelected] =
    useState(false);
  const [isNotPresented, setIsNotPresented] = useState(false);
  const [isSomeNegativeSelected, setIsSomeNegativeSelected] = useState(false);
  const handleInvertedSelectionChange = useCallback(
    (newInvertedStatus) => {
      setSelectionInverted(newInvertedStatus);
    },
    [setSelectionInverted]
  );

  const handleSelectionChangeCallback: (newSelected: DocumentTable[]) => void =
    useCallback(
      (newSelection) => {
        setIsSomeDraftSelected(
          newSelection.some((xs) => xs.status === DocumentStatus.DRAFT)
        );
        setIsSomePaidSelected(
          newSelection.some((xs) => xs.paidStatus === DocumentPaidStatus.PAID)
        );
        setIsSomeNegativeSelected(
          newSelection.some((xs) => xs.totals.total < 0)
        );
        setIsSomeFreezedSelected(newSelection.some((xs) => xs.isFreezed));
        setIsSomeReceiptSelected(newSelection.some((xs) => !xs.contact));
        setIsSomeCorrectedSelected(
          newSelection.some((xs) => xs.status === DocumentStatus.CORRECTED)
        );
        setIsSomeReplacedSelected(
          newSelection.some((xs) => xs.status === DocumentStatus.REPLACED)
        );
        setIsSomeHasBaiVatNumberErrors(
          newSelection.some((xs) => hasBaiVatNumberErrors(xs))
        );
        setIsSingleVoidableSelected(
          newSelection.length === 1 && canVoidDocument(newSelection[0])
        );
        setOnlyTestInvoicesSelected(
          !!newSelection.length &&
            !newSelection.some((xs) => xs.statusInfo !== StatusInfo.TEST)
        );
        setIsSomeCorrectiveSelected(
          newSelection.some((xs) => xs.documentType === DocumentType.CORRECTIVE)
        );
        setIsSomeVoidSelected(
          newSelection.some((xs) => xs.status === DocumentStatus.VOID)
        );
        setIsNotPresented(
          newSelection.some(
            (xs) =>
              xs.digitalDocument?.status !== DigitalDocumentStatus.PRESENTED
          )
        );
        setIsSomeIsUploadFile(newSelection.some((xs) => xs.hasFile));
      },
      [setIsSomeDraftSelected]
    );

  const handleSelectionChange =
    customHandleSelectionChange ?? handleSelectionChangeCallback;

  const {
    isLoading,
    data,
    pagination,
    sortBy,
    filters,
    onPageSizeChange,
    onPageChange,
    setSortBy,
    setFilters,
    deleteDocuments,
    downloadDocument,
    downloadZip,
    downloadExcel,
    editDocument,
    sendDocument,
    copyDocument,
    replaceToInvoice,
    createPayment,
    rectificateDocument,
    voidSelectedDocument,
    emitDraft,
    createRemittance,
    issueDraftReceipt,
  } = useDocumentListDataTable({
    status,
    isExpensesList: false,
    canVoidDocument: hasElectronicProvider,
    queryParams: queryParamsState[currentTab],
    searchTerm,
    updateQueryParams,
    extraQuery,
    excludeTestInvoices,
  });
  const { columns } = useDocumentListConfig({ documentKind: currentTab });
  const bulkActions = useDocumentBulkActions({
    t,
    filters,
    status,
    isEditor: !!isEditor,
    isUserReader: !!isUserReader,
    hasElectronicProvider,
    canSendElectronicDocument,
    isNotPresented,
    isSomeDraftSelected,
    isSomeReceiptSelected,
    isSomeFreezedSelected,
    isSomeCorrectiveSelected,
    isSomeReplacedSelected,
    isSomeCorrectedSelected,
    isSomeVoidSelected,
    isSomePaidSelected,
    isSomeHasBaiVatNumberErrors,
    isSomeIsUploadFile,
    onlyTestInvoicesSelected,
    isSingleVoidableSelected,
    isSomeNegativeSelected,
    selectionInverted,
    setSingleVoidableSelected: setIsSingleVoidableSelected,
    setIsSomeDraftSelected,
    setSelectionInverted,
    deleteDocuments,
    editDocument,
    emitDraft,
    copyDocument,
    replaceToInvoice,
    downloadZip,
    voidSelectedDocument,
    rectificateDocument,
    createPayment,
    sendDocument,
    downloadExcel,
    downloadDocument,
    customBulkActions,
    createRemittance,
    issueDraftReceipt,
  });

  function handleFiltersChanged(event: DocumentFilters) {
    handleTagsChanged({ ...filters, ...event, searchTerm });
    closeFilters();
  }

  function handleTagsChanged(event: DocumentFilters) {
    setFilters(event);
  }

  const { counterparty, ...mainFilters } = filters;
  const hasFilters = Object.values(mainFilters).some(
    (value) => value !== undefined && value !== ''
  );

  const showZeroState = data && !data.count && !isLoading && !hasFilters;

  useEffect(() => {
    setFiltersDisabled(Boolean(showZeroState));
  }, [showZeroState, setFiltersDisabled]);

  useEffect(() => {
    setShowBigLoading(false);
  }, [data, setShowBigLoading, isLoading]);

  const items = useMemo((): DocumentTable[] => {
    return (
      data?.items.map((item) => ({
        ...item,
        completeSerialCode: `${item.serialCode}${item.code || ''}`,
        notSelectable: item.statusInfo === StatusInfo.TEST,
      })) ?? []
    );
  }, [data]);

  if (showZeroState) {
    setFiltersDisabled(true);
    return CustomZeroState ?? <DocumentsZeroState {...{ status }} />;
  }

  if (isLoading && showBigLoading) {
    return (
      <Box position="relative" height="440px">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <DataTableFiltersWrapper
        modalTitle={t(filter.dialogDescription)}
        open={filtersActive}
        closeFilters={closeFilters}
        id="documentsFilters"
      >
        <DocumentsFilterForm
          statusInfo={filters.statusInfo}
          issuedDateFrom={filters.issuedDateFrom}
          issuedDateTo={filters.issuedDateTo}
          dueDate={filters.dueDate}
          minAmount={filters.minAmount}
          maxAmount={filters.maxAmount}
          digitalDocumentStatus={filters.digitalDocumentStatus}
          onSubmit={handleFiltersChanged}
          onCancel={closeFilters}
          disableStatus={status === 'DRAFT'}
          currentTab={currentTab}
          documentType={DocumentType.INVOICE}
        />
      </DataTableFiltersWrapper>
      <TagList
        {...{
          onChangeSearch: handleTagsChanged,
          filters,
          documentGender: ValidGenders.F,
        }}
      />
      <StyledDocumentsTable>
        <DataTable<DocumentTable, keyof DocumentTable>
          allowSelectAll={allowSelectAll}
          multiple
          keyName="id"
          className={className}
          columns={customColumns ?? columns}
          actions={bulkActions}
          data={items}
          page={pagination.page}
          pageSize={pagination.pageSize}
          totalCount={data?.count ?? 0}
          sortBy={sortBy}
          loading={isLoading}
          emptyDataTitle={t(zeroResults.title)}
          emptyDataSubTitle={t(zeroResults.subtitle)}
          filters={filters}
          onSelect={(d) =>
            customOnSelect ??
            history.push({
              pathname: `/documents/issued/${d.id}`,
              state: queryParamsState,
            })
          }
          onSelectionChange={handleSelectionChange}
          onInvertedChange={handleInvertedSelectionChange}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          onSortChange={(p) => {
            setSortBy(p);
          }}
          data-testid="documents-list"
          initialSelection={preSelectedDocuments}
        />
      </StyledDocumentsTable>
    </>
  );
};

export default DocumentList;
