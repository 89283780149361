import { FC, useEffect, useMemo } from 'react';

import { useCombobox } from 'downshift';
import { useField } from 'formik';

import useTheme from 'modules/Theme/hooks/useTheme';
import Box from 'modules/Ui/Box';
import { FormikFieldProps } from 'modules/Ui/Formik/FormikFieldProps';
import { IconSearch } from 'modules/Ui/Icons';
import Text from 'modules/Ui/Text';

import Button from '../Button';
import Description from '../Commons/Description';
import Input from '../Commons/Input';
import InputWrapper from '../Commons/InputWrapper';
import Label from '../Commons/Label';
import LabelText from '../Commons/LabelText';
import ListExpandable, {
  ExpandableListItemRendererProps,
  Item,
} from './components/ListExpandable';

export interface InputExpandableProps extends FormikFieldProps {
  items: Item[];
  seeMoreOptionsLabel?: string;
  onInputValueChange?: (inputValue: string) => void;
  isLoading?: boolean;
  expandableListItemRenderer: FC<ExpandableListItemRendererProps>;
  onSelectedItemChange?: (item: Item) => void;
  onSeeMoreOptionsLabelClick?: () => void;
  loadingMessage?: string;
  noResultsMessage?: string;
  showMoreOptions?: boolean;
  hideExpandableList?: boolean;
}

export const InputExpandable: FC<InputExpandableProps> = ({
  seeMoreOptionsLabel,
  disabled,
  helpText,
  onSeeMoreOptionsLabelClick,
  id,
  items,
  label,
  maxLength,
  placeholder,
  required,
  onInputValueChange,
  isLoading,
  expandableListItemRenderer,
  onSelectedItemChange,
  loadingMessage = 'Loading...',
  noResultsMessage = 'No results found',
  showMoreOptions,
  hideExpandableList,
}) => {
  const theme = useTheme();

  const [field, meta, helpers] = useField(id);
  const { error } = meta;
  const {
    isOpen,
    getLabelProps,
    getInputProps,
    getItemProps,
    selectedItem,
    selectItem,
    setInputValue,
    getToggleButtonProps,
    getMenuProps,
    closeMenu,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      helpers.setValue(inputValue);
      helpers.setTouched(true);
      onInputValueChange?.(inputValue);
    },
    onSelectedItemChange({ selectedItem: newSelectedItem }) {
      onSelectedItemChange?.(newSelectedItem);
      setTimeout(() => {
        closeMenu();
      }, 1);
    },
    items,
    itemToString(item) {
      return item ? item.label : '';
    },
  });

  useEffect(() => {
    setInputValue(field.value);
  }, [field.value]);

  const inputProps = getInputProps();
  const showPlaceholderText =
    isOpen &&
    (isLoading || items.length === 0) &&
    inputProps.value &&
    !hideExpandableList;

  const showExpandableList =
    isOpen && (items.length > 0 || isLoading) && !hideExpandableList;
  const memoInput = useMemo(() => {
    return (
      <>
        <LabelText required={required}>{label}</LabelText>
        <InputWrapper disabled={disabled} error={error} hasIcon>
          <Input
            aria-describedby={`input-expandable-${id}`}
            disabled={disabled}
            error={error}
            iconPosition="end"
            maxLength={maxLength}
            placeholder={placeholder}
            required={required}
            type="search"
            {...inputProps}
            id={id}
            data-testid={id}
            name={id}
          />
          <Button
            aria-label={seeMoreOptionsLabel}
            color={theme.colors.brand500}
            {...getToggleButtonProps()}
            onClick={onSeeMoreOptionsLabelClick}
            ref={null}
          >
            <IconSearch />
          </Button>
        </InputWrapper>
        <Description id={`input-expandable-${id}`} hasError={!!error}>
          {error ?? helpText}
        </Description>
        {showPlaceholderText && (
          <Box
            backgroundColor="gray0"
            border={`1px solid ${theme.colors.primary200}`}
            borderRadius="0px 0px 4px 4px"
            boxShadow={`0 4px 10px  ${theme.colors.shadow}`}
            fadeIn
            padding="12px 8px 6px 12px"
            position="absolute"
            tag="ul"
            top="64px"
            width="calc(100% - 20px)"
            zIndex="10"
          >
            <Text
              color="gray800"
              fontSize={14}
              hasEllipsis
              lineHeight={18}
              tag="span"
            >
              {isLoading ? loadingMessage : noResultsMessage}
            </Text>
          </Box>
        )}
      </>
    );
  }, [
    id,
    error,
    disabled,
    placeholder,
    required,
    maxLength,
    inputProps.value,
    showPlaceholderText,
    label,
    isLoading,
  ]);
  return (
    <div>
      <Label position="relative" {...getLabelProps()} htmlFor={id}>
        {memoInput}
        <ListExpandable
          getMenuProps={getMenuProps}
          expandableListItemRenderer={expandableListItemRenderer}
          seeMoreOptionsLabel={seeMoreOptionsLabel}
          getItemProps={getItemProps}
          onSeeMoreOptionsLabelClick={onSeeMoreOptionsLabelClick}
          isOpen={showExpandableList}
          selectItem={selectItem}
          items={items}
          selectedItem={selectedItem}
          showMoreOptions={showMoreOptions}
        />
      </Label>
    </div>
  );
};
