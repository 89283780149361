import React, { ChangeEvent, useCallback } from 'react';

import { useField } from 'formik';

import RadioButtonCard, {
  RadioButtonCardProps,
} from 'modules/Ui/Form/RadioButtonCard';

import useFocusHandler from './useFocusHandler';

const RadioButtonCardFormik: React.FC<RadioButtonCardProps> = ({
  onChange,
  height,
  ...props
}) => {
  const { name, id } = props;
  const [field, meta, helpers] = useField(name);
  const onChangeHandler = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(value);
      helpers.setTouched(true);
      return helpers.setValue(value);
    },
    [helpers, onChange]
  );
  const onFocusHandler = useFocusHandler(name || id);

  return (
    <RadioButtonCard
      {...field}
      height={height}
      checked={meta.value === props.value}
      error={meta.error}
      {...props}
      onChange={onChangeHandler}
      onFocus={onFocusHandler}
    />
  );
};
export default RadioButtonCardFormik;
