import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { emptyContact } from 'modules/Contacts/models';
import { DocumentDefaultValues } from 'modules/DefaultValues/models/document-default-values';
import { createAdditionalExpense } from 'modules/Documents/helpers/default-additionalExpense';
import { createDefaultConcept } from 'modules/Documents/helpers/default-concept';
import {
  DocumentPeriodType,
  DocumentStatus,
  Document,
  DocumentType,
} from 'modules/Documents/models/document';
import { NewDocumentForm } from 'modules/Documents/models/form';
import { PersonType } from 'modules/Documents/models/personType';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';

import { processPaymentMethodsFromDocument } from './helpers';
import processConcepts from './processConcepts';
import { processVisualLines } from './processVisualLines';

const getCorrectedDocuments = (
  document: Document,
  isReplacing?: boolean,
  isDraft?: boolean
) => {
  const { id, correctedDocuments } = document;
  if (isReplacing && isDraft) {
    return correctedDocuments?.map((data) => data.id);
  }
  if (isReplacing && !isDraft) {
    return [id];
  }
  return undefined;
};

const copyDocumentValues = (
  document: Document,
  initialSerieValue: string | null,
  initialCode: number,
  initialSerialCode: string,
  isDraft?: boolean,
  paymentMethods?: CreatedPaymentMethod[],
  savedDefaultValues?: DocumentDefaultValues | null,
  isReplacing?: boolean
): NewDocumentForm => {
  const showInitialCode = true;
  const isQuoteOrProforma =
    document.documentType === DocumentType.QUOTE ||
    document.documentType === DocumentType.PROFORMA;

  const operationDateTime =
    document.operationDateTime || document.issuedDateTime;
  const correctedDocuments = getCorrectedDocuments(
    document,
    isReplacing,
    isDraft
  );

  const preserveContact = !!(isReplacing && document.contact);

  const mergedConcepts = [
    ...processConcepts(document.concepts),
    ...processVisualLines(document.visualLines ?? []),
  ].sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));

  return {
    showLinesWithoutTaxes: savedDefaultValues?.showLinesWithoutTaxes,
    hasAdditionalExpenses: !isEmpty(document.additionalExpenses),
    additionalExpenses: !isEmpty(document.additionalExpenses)
      ? document.additionalExpenses
      : [createAdditionalExpense()],
    creationDate: new Date(),
    dueDate: undefined,
    code: showInitialCode ? initialCode : document.code,
    initDateTime: new Date(),
    newDocumentPeriod: DocumentPeriodType.MONTHLY,
    dueDocumentPeriod: DocumentPeriodType.MONTHLY,
    concepts: !isEmpty(mergedConcepts)
      ? mergedConcepts
      : [createDefaultConcept(document.taxRegime || '')],
    showComments: !!document.comments,
    isFileAttached: isQuoteOrProforma && !!document.hasFile,
    comments: document.comments || '',
    contact: preserveContact ? document.contact : { ...emptyContact },
    hasFile: !!document.hasFile,
    hasRetentions: !!document.totals?.retentionPercentage,
    hasSalesEqualizationTax: document.hasSalesEqualizationTax,
    reference: document.reference || '',
    retentionPercentage: document.totals?.retentionPercentage || 15,
    hasPaidAmount: !!document.totals?.paidAmount,
    paidAmount: document.totals?.paidAmount || 0,
    serialCode: initialSerialCode,
    serieId: initialSerieValue,
    isDefaultDraft: !!isDraft,
    // Issued by default but can be saved as draft
    status: DocumentStatus.ISSUED,
    // Bai
    operationDescription: document.operationDescription || '',
    operationDateTime:
      isReplacing && operationDateTime
        ? new Date(operationDateTime)
        : undefined,
    naturalPerson: document.naturalPerson,
    naturalPersonType: document.naturalPerson
      ? PersonType.NATURAL
      : PersonType.LEGAL,
    epigraphIAE: document.epigraphIAE,
    chargingCriterionIRPF: document.chargingCriterionIRPF,
    hasCustomIRPF: !!document.customIRPF,
    customIRPF: document.customIRPF,
    taxRegime: document.taxRegime,
    templateId: document.templateId || savedDefaultValues?.templateId,
    currency: document.currency,
    paymentMethods: processPaymentMethodsFromDocument({
      documentMethods: document.paymentMethodsAccepted,
      defaultMethods: paymentMethods,
      preserveContactMethods: preserveContact,
    }),
    correctedDocuments,
    showProductReference: savedDefaultValues?.showProductReference,
    requestId: uuidv4(),
  };
};
export default copyDocumentValues;
