import { isEmpty } from 'lodash';

import { Concept } from 'modules/Documents/models/document';
import { Product } from 'modules/Products/models';
import { TaxKey } from 'modules/Taxes';

const getTotalAmount = (total?: number, totalAmount?: number) => {
  if (isNaN(Number(total))) {
    return totalAmount;
  }
  return total ?? totalAmount;
};

export function processConcepts(
  concepts: Concept[],
  options: {
    hasElectronicProvider?: boolean;
    hasAutoCalculate?: boolean;
    hasTaxExemptionCause?: boolean;
  }
) {
  const { hasElectronicProvider, hasAutoCalculate, hasTaxExemptionCause } =
    options;
  return concepts.map((concept: Concept) => ({
    taxKey: concept.taxKey,
    name: sanitizeText(concept.name),
    description: sanitizeText(concept.description),
    order: concept.order,
    groupName: concept.groupName || '',
    quantity: concept.quantity || 0,
    unitPrice: concept.unitPrice || 0,
    discountPercentage: concept.discountPercentage
      ? concept.discountPercentage
      : 0,
    ...(hasElectronicProvider && {
      taxExemption: processTaxExemption(
        concept,
        hasElectronicProvider,
        hasTaxExemptionCause
      ),
    }),
    product: concept.product,
    isDetachedFromProduct: isDetachedFromProduct(concept),
    productReference: concept.productReference,
    operation: concept.operation,
    totalAmount: getTotalAmount(concept.total, concept.totalAmount),
    fixedTaxAmountByClient:
      hasAutoCalculate || isEmpty(concept.fixedTaxAmountByClient) // we check emptiness because it can be empty string when not set, because null is not a valid value for numeric input
        ? null
        : concept.fixedTaxAmountByClient,
  }));
}

function processTaxExemption(
  concept: Concept,
  hasElectronicProvider: boolean,
  hasTaxExemptionCause?: boolean
) {
  if (
    !hasTaxExemptionCause &&
    !needsCauseExemption(hasElectronicProvider, concept.taxKey)
  )
    return null;
  return {
    taxExemptionGroup: concept.taxExemption?.taxExemptionGroup,
    taxExemptionCause: concept.taxExemption?.taxExemptionCause,
  };
}

const needsCauseExemption = (hasElectronicProvider: boolean, taxKey?: TaxKey) =>
  hasElectronicProvider && taxKey === TaxKey.IVA_0;

function isDetachedFromProduct(concept: Concept) {
  if (conceptHasChanged(concept, concept.productValues)) {
    return true;
  }

  return concept.isDetachedFromProduct;
}

function sanitizeText(text?: string) {
  // eslint-disable-next-line no-control-regex
  return text?.replace(/\u0000/g, '');
}

export function conceptHasChanged(
  concept: Concept,
  product?: Partial<Product>
) {
  if (!product || !concept.product) {
    return false;
  }

  return conceptWithProductHasChanged(concept, product);
}

export function conceptWithProductHasChanged(
  concept: Concept,
  product?: Partial<Product>
) {
  const hasSameName = concept.name === product?.name;
  const hasDescription = !!concept.description || !!product?.description;
  const hasSameDescription = hasDescription
    ? concept.description === product?.description
    : true;
  const hasSameTaxKey = concept.taxKey === product?.taxKey;
  const hasSameUnitPrice = concept.unitPrice === product?.sellingPrice;
  return (
    !hasSameName || !hasSameDescription || !hasSameTaxKey || !hasSameUnitPrice
  );
}
