import { europeanCountries } from 'modules/Address/models/countries';
import { VatNumberType } from 'modules/Contacts/models';

function checkIfHasExemptionNotSubjectByIE({
  contactCountry,
  contactVatNumberType,
}: {
  contactCountry?: string;
  contactVatNumberType?: VatNumberType;
}): boolean {
  return !!(
    contactCountry &&
    contactVatNumberType &&
    contactCountry in europeanCountries &&
    contactCountry !== 'ES' &&
    contactVatNumberType !== VatNumberType.NIF_IVA
  );
}

export default checkIfHasExemptionNotSubjectByIE;
