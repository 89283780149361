import getExpensesData, {
  getEmptyExpenseData,
} from 'modules/Dashboard/helpers/getExpensesData';
import { ExpenseCategories } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';

export interface Option {
  label: string;
  id?: ExpenseCategories;
}

export default function useExpenseLedger() {
  const { t } = useTranslations();

  const emptyOption = [
    {
      ...getEmptyExpenseData(t),
      id: ExpenseCategories.Empty,
    },
  ];

  const expensesData = getExpensesData(t);
  const options: Option[] = Object.entries(expensesData).map(([id, data]) => ({
    label: data.label,
    id: id as ExpenseCategories,
  }));

  return [
    ...emptyOption,
    ...options.sort((a, b) => a.label.localeCompare(b.label)),
  ];
}
