import { FC, createElement } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentBusiness } from 'modules/Business/Current/selectors';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import Cancelled from 'modules/Subscriptions/cancelled/Cancelled';
import { selectHasExternalPlan } from 'modules/Subscriptions/detail/selectors';
import { useLicensePack } from 'modules/Subscriptions/hooks';
import useIsExternalRenewablePlan from 'modules/Subscriptions/hooks/useIsExternalRenewablePlan';
import useIsTrustedBusinessCampaign from 'modules/Subscriptions/hooks/useIsTrustedBusinessCampaign';
import { Plan } from 'modules/Subscriptions/models';
import useTheme from 'modules/Theme/hooks/useTheme';
import { Box, Text } from 'modules/Ui';

import { discountBanner } from '../../../messages';
import { PlansInfo } from '../PlansInfo';
import { SubscriptionPlans } from '../SubscriptionPlans';
import DiscountBanner from './DiscountBanner';
import Faqs from './Faqs';
import { useGetPartnerName } from './getPartnerName';

interface Props {
  activePlan?: string;
  hasBookkeeper: boolean;
  hasCampaign: boolean;
  hasSubscription: boolean;
  onChangeBusinessClick(): void;
  onSelectPlan(data: Plan): void;
  plans: Plan[];
}

const Plans: FC<Props> = ({
  activePlan,
  hasBookkeeper,
  hasCampaign,
  hasSubscription,
  onChangeBusinessClick,
  onSelectPlan,
  plans,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const business = useSelector(selectCurrentBusiness);
  const licensePack = useLicensePack();
  const { isTrustedCampaign } = useIsTrustedBusinessCampaign();
  const isExternalRenewablePlan = useIsExternalRenewablePlan();
  const hasPartner = business.partner !== null;
  const showAddBookkeeperBanner =
    !hasBookkeeper &&
    !hasSubscription &&
    !hasCampaign &&
    !hasPartner &&
    !isTrustedCampaign;
  const showBookkeeperDiscountBanner =
    hasBookkeeper && hasCampaign && !isTrustedCampaign;
  const isSubscriptionExternal = useSelector(selectHasExternalPlan);
  const partnerName = useGetPartnerName();
  const bold = (chunk: unknown[]) =>
    createElement(Text, {
      children: chunk,
      color: 'inherit',
      fontSize: 'inherit',
      marginRight: '4px',
      tag: 'strong',
    });

  if (isSubscriptionExternal && !isExternalRenewablePlan && !licensePack) {
    return <Cancelled partner={partnerName} />;
  }

  return (
    <Box
      contentVisibility={{ _: 'auto', sm: 'initial' }}
      backgroundColor="auxiliary"
      tag="section"
    >
      <>
        {showBookkeeperDiscountBanner && (
          <DiscountBanner
            text={t(discountBanner.bookkeeperDiscountText, { bold })}
            pill={t(discountBanner.pill)}
          />
        )}
        {showAddBookkeeperBanner && (
          <DiscountBanner
            text={t(discountBanner.addBookkeeperText, { bold })}
            pill={t(discountBanner.pill)}
          />
        )}
        <SubscriptionPlans
          activePlan={activePlan}
          onChangeBusinessClick={onChangeBusinessClick}
          onSelectPlan={onSelectPlan}
          plans={plans}
        />
        <Box
          contentVisibility={{ _: 'initial', sm: 'auto' }}
          tag="section"
          marginBottom="24px"
        >
          <PlansInfo plural={plans.length > 2} />
          <Box
            backgroundColor="gray0"
            border={{ sm: `1px solid ${theme.colors.primary100}` }}
            marginBottom={{ sm: '16px' }}
            padding={{ sm: '16px' }}
          >
            <Faqs />
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default Plans;
