import { defineMessages } from 'react-intl';

export const documentData = defineMessages({
  reference: { id: 'gofre.common.label.reference' },
  amountRetention: { id: 'gofre.Documents.Upload.Form.Data.amountRetention' },
  autoCalculate: { id: 'gofre.Documents.Upload.Form.Data.autoCalculate' },
  autoCalculateCheckTooltip: {
    id: 'gofre.Documents.DocumentDataStep.autoCalculateCheck-tooltip',
  },
  category: { id: 'gofre.Documents.Upload.Form.Data.category' },
  categoryInfo: { id: 'gofre.Documents.Upload.Form.Data.categoryInfo' },
  conceptAdd: { id: 'gofre.Documents.Upload.Form.Data.conceptAdd' },
  country: { id: 'gofre.Documents.Upload.Form.Data.country' },
  createCustomer: { id: 'gofre.Documents.Upload.Form.Data.createCustomer' },
  createProvider: { id: 'gofre.Documents.Upload.Form.Data.createProvider' },
  conceptRemove: { id: 'gofre.Documents.Upload.Form.Data.conceptRemove' },
  customerData: { id: 'gofre.Documents.Upload.Form.Data.customerData' },
  creationDate: { id: 'gofre.Documents.Upload.Form.Data.creationDate' },
  recurrentCreationDate: {
    id: 'gofre.Documents.Upload.Form.Data.recurrent.creationDate',
  },
  creationDatePlaceholder: {
    id: 'gofre.common.placeholder.date',
  },
  recurrentNextDate: {
    id: 'gofre.Documents.Upload.Form.Data.recurrent.nextDate',
  },
  nextDatePlaceholder: {
    id: 'gofre.common.placeholder.date',
  },
  documentNumber: { id: 'gofre.Documents.Upload.Form.Data.documentNumber' },
  dueDate: { id: 'gofre.Documents.Upload.Form.Data.dueDate' },
  economicResume: { id: 'gofre.Documents.Upload.Form.legend.economicResume' },
  fiscalName: { id: 'gofre.Documents.Upload.Form.Data.fiscalName' },
  formLegend: { id: 'gofre.Documents.Upload.Form.Data.legend' },
  isPaid: { id: 'gofre.Documents.Upload.Form.Data.isPaid' },
  isPaidExpense: { id: 'gofre.Documents.Upload.Form.Data.isPaidExpense' },
  nif: { id: 'gofre.Documents.Upload.Form.Data.nif' },
  paymentCash: { id: 'gofre.common.label.cash' },
  paymentConfirming: { id: 'gofre.Documents.Upload.Form.Data.confirming' },
  paymentCreditCard: { id: 'gofre.Documents.Upload.Form.Data.creditCard' },
  paymentDirectDebit: { id: 'gofre.common.label.bank_transfer' },
  paymentMethod: { id: 'gofre.Documents.Upload.Form.Data.paymentMethod' },
  paymentOther: { id: 'gofre.common.label.other' },
  paymentPromissoryNote: {
    id: 'gofre.Documents.Upload.Form.Data.promissoryNote',
  },
  paymentTransfer: { id: 'gofre.common.label.transfer' },
  percentageRetention: {
    id: 'gofre.Documents.Upload.Form.Data.percentageRetention',
  },
  postalAddress: { id: 'gofre.Documents.Upload.Form.Data.postalAddress' },
  postalCode: { id: 'gofre.Documents.Upload.Form.Data.postalCode' },
  providerData: { id: 'gofre.Documents.Upload.Form.Data.providerData' },
  removeCustomer: { id: 'gofre.Documents.Upload.Form.Data.removeCustomer' },
  removeProvider: { id: 'gofre.Documents.Upload.Form.Data.removeProvider' },
  searchCustomer: { id: 'gofre.Documents.Upload.Form.Data.searchCustomer' },
  searchProvider: { id: 'gofre.Documents.Upload.Form.Data.searchProvider' },
  select: { id: 'gofre.common.action.select' },
  scanDocument: { id: 'gofre.Documents.Upload.scanDocument' },
  serialCode: {
    id: 'gofre.Documents.Upload.Form.serial-code',
  },
  rectifySerialCode: {
    id: 'gofre.Documents.Upload.Form.rectify.serial-code',
  },
  serieTooltip: {
    id: 'gofre.Documents.DocumentDataStep.Form.serie-tooltip',
  },
  taxAmount: { id: 'gofre.Documents.Upload.Form.Data.taxAmount' },
  taxes: { id: 'gofre.Documents.Upload.Form.Data.taxes' },
  taxLineTaxBase: { id: 'gofre.Documents.Upload.Form.Data.tax-base' },
  totalLabel: { id: 'gofre.common.label.total_invoice' },
  vatNumber: { id: 'gofre.Documents.DocumentDataStep.Form.vat-number-bai' },
  remainingScans: { id: 'gofre.Documents.Upload.remaining-scans' },
  remainingScansUnlimited: {
    id: 'gofre.Documents.Upload.remaining-scans-unlimited',
  },
  uploadSerieTooltip: {
    id: 'gofre.Documents.DocumentDataStep.Form.serie-tooltip-upload',
  },
  period: { id: 'gofre.Documents.DocumentDataDisplay.period' },
  duePeriod: { id: 'gofre.Documents.DocumentDataDisplay.due-period' },
  identifier: { id: 'gofre.Documents.DocumentDataDisplay.identifier' },
  identifierPlaceholder: {
    id: 'gofre.Documents.DocumentDataDisplay.identifier-placeholder',
  },
  identifierInfo: { id: 'gofre.Documents.DocumentDataDisplay.identifier-info' },
  additionalExpensesAddButton: {
    id: 'gofre.Documents.ConceptsStep.additional-expenses-add',
  },
});

export const scanModals = defineMessages({
  upgradeTitle: { id: 'gofre.Documents.Scan.Modal.upgradeTitle' },
  upgradeSubTitle: { id: 'gofre.Documents.Scan.Modal.upgradeSubTitle' },
  upgradeCta: { id: 'gofre.Documents.Scan.Modal.upgradeCta' },
  upgradeCtaSecondary: { id: 'gofre.common.action.understood' },
  upgradeText: { id: 'gofre.Documents.Scan.Modal.upgradeText' },
  upgradeTextNative: { id: 'gofre.Documents.Scan.Modal.upgradeTextNative' },
  limitTitle: { id: 'gofre.Documents.Scan.Modal.limitTitle' },
  limitText: { id: 'gofre.Documents.Scan.Modal.limitText' },
  limitTextNative: { id: 'gofre.Documents.Scan.Modal.limitTextNative' },
  limitTextUnlimited: { id: 'gofre.Documents.Scan.Modal.limitTextUnlimited' },
  limitCta: { id: 'gofre.Documents.Scan.Modal.limitCta' },
  limitCtaSecondary: { id: 'gofre.common.action.understood' },
  limitCancel: { id: 'gofre.common.action.cancel' },
  limitTextUnlimitedNative: {
    id: 'gofre.Documents.Scan.Modal.limitTextUnlimitedNative',
  },
  mailTo: {
    id: 'gofre.common.billin.hola_billin_eu',
  },
  phoneNumber: {
    id: 'gofre.common.billin.91_831_88_83',
  },
});
export const formErrors = defineMessages({
  dueDateBeforeCreationDate: {
    id: 'gofre.Documents.Form.dueDateBeforeCreationDate',
  },
  percentage: {
    id: 'gofre.Documents.Upload.Form.percentageGreaterThanHundred',
  },
  postalCodeNumeric: { id: 'gofre.Documents.Upload.Form.postalCodeInvalid' },
  vatNumberInvalid: { id: 'gofre.common.validation.nif_invalid' },
  noDocument: { id: 'gofre.Documents.Upload.no-document' },
  noDocumentText: { id: 'gofre.Documents.Upload.no-document-text' },
  noDocumentCta: { id: 'gofre.common.action.accept' },
});

export const uploadTitles = defineMessages({
  uploadInvoice: { id: 'gofre.Documents.Upload.Header.invoice' },
  uploadExpense: { id: 'gofre.Documents.Upload.Header.expense' },
  uploadMultipleExpense: {
    id: 'gofre.Documents.Upload.Header.multiple-expense',
  },
  uploadInvoiceButton: { id: 'gofre.Documents.Upload.Button.invoice' },
  uploadExpenseButton: { id: 'gofre.Documents.Upload.Button.expense' },
  uploadingFile: { id: 'gofre.Documents.Upload.Button.uploading-file' },
  uploadingTest: { id: 'gofre.Documents.Upload.Button.test' },
});

export const uploadNotifications = defineMessages({
  errorMissingFile: {
    id: 'gofre.Documents.Upload.Notifications.error.missing-file',
  },
  errorUploading: {
    id: 'gofre.Documents.Upload.Notifications.error.uploading',
  },
  errorCreatingPayment: {
    id: 'gofre.Documents.Upload.Notifications.error.creating-payment',
  },
  successInvoice: {
    id: 'gofre.Documents.Upload.Notifications.success-invoice',
  },
  successExpense: {
    id: 'gofre.Documents.Upload.Notifications.success-expense',
  },
  warningPaymentInvoice: {
    id: 'gofre.Documents.Upload.Notifications.warning-payment-invoice',
  },
  warningPaymentExpense: {
    id: 'gofre.Documents.Upload.Notifications.warning-payment-expense',
  },
  successWithNewCustomer: {
    id: 'gofre.Documents.Upload.Notifications.success-with-new-customer',
  },
  successWithNewProvider: {
    id: 'gofre.Documents.Upload.Notifications.success-with-new-provider',
  },
  cta: { id: 'gofre.Documents.Upload.Notifications.cta-contact' },
  duplicateCodeError: {
    id: 'gofre.Documents.Upload.Notification.duplicate-code-error',
  },
});

export const uploaDropDown = defineMessages({
  uploadExpense: { id: 'gofre.Documents.Upload.Dropdown.uploadExpense' },
  uploadRecurrentExpense: {
    id: 'gofre.Documents.Upload.Dropdown.uploadRecurrentExpense',
  },
  uploadMultipleExpense: {
    id: 'gofre.Documents.Upload.Dropdown.uploadMultipleExpense',
  },
  title: { id: 'gofre.Documents.Dialog.ListActions.title' },
});

export const multipleUploadTexts = defineMessages({
  uploading: { id: 'gofre.Documents.Upload.MultipleUpload.uploading' },
  emailCopy: { id: 'gofre.Expenses.List.emailCopy' },
  exitModalTitle: {
    id: 'gofre.Documents.Upload.MultipleUpload.exitModalTitle',
  },
  exitModalText: {
    id: 'gofre.Documents.Upload.MultipleUpload.exitModalText',
  },
  uploadTooLargeError: {
    id: 'gofre.Documents.Upload.MultipleUpload.uploadTooLargeError',
  },
  uploadInvalidTypeError: {
    id: 'gofre.Documents.Upload.MultipleUpload.uploadInvalidTypeError',
  },
  uploadGenericError: {
    id: 'gofre.Documents.Upload.MultipleUpload.uploadGenericError',
  },
  errorsFound: { id: 'gofre.Documents.Upload.MultipleUpload.errorsFound' },
  filesUploading: {
    id: 'gofre.Documents.Upload.MultipleUpload.filesUploading',
  },
  filesUploaded: {
    id: 'gofre.Documents.Upload.MultipleUpload.filesUploaded',
  },
  error: { id: 'gofre.common.error.generic' },
  errorDropYourFilesHere: {
    id: 'gofre.Documents.Upload.MultipleUpload.errorDropYourFilesHere',
  },
  finished: { id: 'gofre.Documents.Upload.MultipleUpload.finished' },
  submit: { id: 'gofre.Documents.Upload.MultipleUpload.submit' },
  sendByEmailButton: {
    id: 'gofre.Documents.Upload.MultipleUpload.sendByEmailButton',
  },
  dropYourFilesHere: {
    id: 'gofre.Documents.Upload.MultipleUpload.dropYourFilesHere',
  },
  uploadYourDocuments: {
    id: 'gofre.Documents.Upload.MultipleUpload.uploadYourDocuments',
  },
  retry: { id: 'gofre.Documents.Upload.MultipleUpload.retry' },
});
