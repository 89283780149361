export enum TaxType {
  IVA = 'IVA',
  IGIC = 'IGIC',
  IPSI = 'IPSI',
}

export enum TaxKey {
  IVA_0 = 'IVA_0',
  IVA_2 = 'IVA_2',
  IVA_2_1 = 'IVA_2_1',
  IVA_3 = 'IVA_3',
  IVA_4 = 'IVA_4',
  IVA_4_8 = 'IVA_4_8',
  IVA_5 = 'IVA_5',
  IVA_5_5 = 'IVA_5_5',
  IVA_6 = 'IVA_6',
  IVA_7 = 'IVA_7',
  IVA_7_5 = 'IVA_7_5',
  IVA_8 = 'IVA_8',
  IVA_8_5 = 'IVA_8_5',
  IVA_9 = 'IVA_9',
  IVA_9_5 = 'IVA_9_5',
  IVA_10 = 'IVA_10',
  IVA_12 = 'IVA_12',
  IVA_13 = 'IVA_13',
  IVA_13_5 = 'IVA_13_5',
  IVA_14 = 'IVA_14',
  IVA_15 = 'IVA_15',
  IVA_16 = 'IVA_16',
  IVA_17 = 'IVA_17',
  IVA_18 = 'IVA_18',
  IVA_19 = 'IVA_19',
  IVA_20 = 'IVA_20',
  IVA_21 = 'IVA_21',
  IVA_22 = 'IVA_22',
  IVA_23 = 'IVA_23',
  IVA_24 = 'IVA_24',
  IVA_25 = 'IVA_25',
  IVA_25_5 = 'IVA_25_5',
  IVA_27 = 'IVA_27',
  IGIC_0 = 'IGIC_0',
  IGIC_3 = 'IGIC_3',
  IGIC_5 = 'IGIC_5',
  IGIC_7 = 'IGIC_7',
  IGIC_9_5 = 'IGIC_9_5',
  IGIC_13_5 = 'IGIC_13_5',
  IGIC_15 = 'IGIC_15',
  IGIC_20 = 'IGIC_20',
  IGIC_35 = 'IGIC_35',
  IPSI_0 = 'IPSI_0',
  IPSI_0_5 = 'IPSI_0_5',
  IPSI_1 = 'IPSI_1',
  IPSI_2 = 'IPSI_2',
  IPSI_3 = 'IPSI_3',
  IPSI_3_5 = 'IPSI_3_5',
  IPSI_4 = 'IPSI_4',
  IPSI_5 = 'IPSI_5',
  IPSI_6 = 'IPSI_6',
  IPSI_7 = 'IPSI_7',
  IPSI_8 = 'IPSI_8',
  IPSI_9 = 'IPSI_9',
  IPSI_10 = 'IPSI_10',
}

export interface TaxOption {
  label: string;
  amount: number;
  type: TaxType;
  value: TaxKey;
  hide?: boolean;
  salesEqTaxPercentage?: number;
  onlyExpenses?: boolean;
}

export interface TaxOptions {
  [key: string]: TaxOption;
}

export const taxOptions: TaxOptions = {
  [TaxKey.IVA_0]: {
    label: '0% IVA',
    amount: 0,
    type: TaxType.IVA,
    value: TaxKey.IVA_0,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_2]: {
    label: '2% IVA',
    amount: 2,
    type: TaxType.IVA,
    value: TaxKey.IVA_2,
    salesEqTaxPercentage: 0.26,
  },
  [TaxKey.IVA_2_1]: {
    label: '2,1% IVA',
    amount: 2.1,
    type: TaxType.IVA,
    value: TaxKey.IVA_2_1,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_3]: {
    label: '3% IVA',
    amount: 3,
    type: TaxType.IVA,
    value: TaxKey.IVA_3,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_4]: {
    label: '4% IVA',
    amount: 4,
    type: TaxType.IVA,
    value: TaxKey.IVA_4,
    salesEqTaxPercentage: 0.5,
  },
  [TaxKey.IVA_4_8]: {
    label: '4,8% IVA',
    amount: 4.8,
    type: TaxType.IVA,
    value: TaxKey.IVA_4_8,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_5]: {
    label: '5% IVA',
    amount: 5,
    type: TaxType.IVA,
    value: TaxKey.IVA_5,
    salesEqTaxPercentage: 0.62,
  },
  [TaxKey.IVA_5_5]: {
    label: '5,5% IVA',
    amount: 5.5,
    type: TaxType.IVA,
    value: TaxKey.IVA_5_5,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_6]: {
    label: '6% IVA',
    amount: 6,
    type: TaxType.IVA,
    value: TaxKey.IVA_6,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_7]: {
    label: '7% IVA',
    amount: 7,
    type: TaxType.IVA,
    value: TaxKey.IVA_7,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_7_5]: {
    label: '7,5% IVA',
    amount: 7.5,
    type: TaxType.IVA,
    value: TaxKey.IVA_7_5,
    salesEqTaxPercentage: 1,
  },
  [TaxKey.IVA_8]: {
    label: '8% IVA',
    amount: 8,
    type: TaxType.IVA,
    value: TaxKey.IVA_8,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_8_5]: {
    label: '8,5% IVA',
    amount: 8.5,
    type: TaxType.IVA,
    value: TaxKey.IVA_8_5,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_9]: {
    label: '9% IVA',
    amount: 9,
    type: TaxType.IVA,
    value: TaxKey.IVA_9,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_9_5]: {
    label: '9,5% IVA',
    amount: 9.5,
    type: TaxType.IVA,
    value: TaxKey.IVA_9_5,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_10]: {
    label: '10% IVA',
    amount: 10,
    type: TaxType.IVA,
    value: TaxKey.IVA_10,
    salesEqTaxPercentage: 1.4,
  },
  [TaxKey.IVA_12]: {
    label: '12% IVA',
    amount: 12,
    type: TaxType.IVA,
    value: TaxKey.IVA_12,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_13]: {
    label: '13% IVA',
    amount: 13,
    type: TaxType.IVA,
    value: TaxKey.IVA_13,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_13_5]: {
    label: '13,5% IVA',
    amount: 13.5,
    type: TaxType.IVA,
    value: TaxKey.IVA_13_5,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_14]: {
    label: '14% IVA',
    amount: 14,
    type: TaxType.IVA,
    value: TaxKey.IVA_14,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_15]: {
    label: '15% IVA',
    amount: 15,
    type: TaxType.IVA,
    value: TaxKey.IVA_15,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_16]: {
    label: '16% IVA',
    amount: 16,
    type: TaxType.IVA,
    value: TaxKey.IVA_16,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_17]: {
    label: '17% IVA',
    amount: 17,
    type: TaxType.IVA,
    value: TaxKey.IVA_17,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_18]: {
    label: '18% IVA',
    amount: 18,
    type: TaxType.IVA,
    value: TaxKey.IVA_18,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_19]: {
    label: '19% IVA',
    amount: 19,
    type: TaxType.IVA,
    value: TaxKey.IVA_19,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_20]: {
    label: '20% IVA',
    amount: 20,
    type: TaxType.IVA,
    value: TaxKey.IVA_20,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_21]: {
    label: '21% IVA',
    amount: 21,
    type: TaxType.IVA,
    value: TaxKey.IVA_21,
    salesEqTaxPercentage: 5.2,
  },
  [TaxKey.IVA_22]: {
    label: '22% IVA',
    amount: 22,
    type: TaxType.IVA,
    value: TaxKey.IVA_22,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_23]: {
    label: '23% IVA',
    amount: 23,
    type: TaxType.IVA,
    value: TaxKey.IVA_23,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_24]: {
    label: '24% IVA',
    amount: 24,
    type: TaxType.IVA,
    value: TaxKey.IVA_24,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_25]: {
    label: '25% IVA',
    amount: 25,
    type: TaxType.IVA,
    value: TaxKey.IVA_25,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_25_5]: {
    label: '25,5% IVA',
    amount: 25.5,
    type: TaxType.IVA,
    value: TaxKey.IVA_25_5,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IVA_27]: {
    label: '27% IVA',
    amount: 27,
    type: TaxType.IVA,
    value: TaxKey.IVA_27,
    salesEqTaxPercentage: 0,
  },
  [TaxKey.IGIC_0]: {
    label: '0% IGIC',
    amount: 0,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_0,
  },
  [TaxKey.IGIC_3]: {
    label: '3% IGIC',
    amount: 3,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_3,
  },
  [TaxKey.IGIC_5]: {
    label: '5% IGIC',
    amount: 5,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_5,
  },
  [TaxKey.IGIC_7]: {
    label: '7% IGIC',
    amount: 7,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_7,
  },
  [TaxKey.IGIC_9_5]: {
    label: '9,5% IGIC',
    amount: 9.5,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_9_5,
  },
  [TaxKey.IGIC_13_5]: {
    label: '13,5% IGIC',
    amount: 13.5,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_13_5,
  },
  [TaxKey.IGIC_15]: {
    label: '15% IGIC',
    amount: 15,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_15,
  },
  [TaxKey.IGIC_20]: {
    label: '20% IGIC',
    amount: 20,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_20,
  },
  [TaxKey.IGIC_35]: {
    label: '35% IGIC',
    amount: 35,
    type: TaxType.IGIC,
    value: TaxKey.IGIC_35,
  },
  [TaxKey.IPSI_0]: {
    label: '0% IPSI',
    amount: 0,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_0,
  },
  [TaxKey.IPSI_0_5]: {
    label: '0,5% IPSI',
    amount: 0.5,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_0_5,
  },
  [TaxKey.IPSI_1]: {
    label: '1% IPSI',
    amount: 1,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_1,
  },
  [TaxKey.IPSI_2]: {
    label: '2% IPSI',
    amount: 2,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_2,
  },
  [TaxKey.IPSI_3]: {
    label: '3% IPSI',
    amount: 3,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_3,
  },
  [TaxKey.IPSI_3_5]: {
    label: '3,5% IPSI',
    amount: 3.5,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_3_5,
  },
  [TaxKey.IPSI_4]: {
    label: '4% IPSI',
    amount: 4,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_4,
  },
  [TaxKey.IPSI_5]: {
    label: '5% IPSI',
    amount: 5,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_5,
  },
  [TaxKey.IPSI_6]: {
    label: '6% IPSI',
    amount: 6,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_6,
  },
  [TaxKey.IPSI_7]: {
    label: '7% IPSI',
    amount: 7,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_7,
  },
  [TaxKey.IPSI_8]: {
    label: '8% IPSI',
    amount: 8,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_8,
    hide: true,
  },
  [TaxKey.IPSI_9]: {
    label: '9% IPSI',
    amount: 9,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_9,
  },
  [TaxKey.IPSI_10]: {
    label: '10% IPSI',
    amount: 10,
    type: TaxType.IPSI,
    value: TaxKey.IPSI_10,
  },
};

export const getVisibleTaxOptions = ({
  currentTax,
  onlyExpenses,
  hasElectronicProvider = false,
  showEuropeanTaxes = false,
}: {
  currentTax: TaxKey;
  onlyExpenses?: boolean;
  hasElectronicProvider?: boolean;
  showEuropeanTaxes?: boolean;
}): TaxOption[] => {
  const filteredOptions = Object.values(taxOptions).filter((tax) => {
    if (tax.onlyExpenses === true && !onlyExpenses) {
      return false;
    }
    const hideEuropeanTaxes =
      !showEuropeanTaxes && isEuropeanNoSpainTaxKey(tax.value);
    if (hideEuropeanTaxes) {
      const isCurrentTax = tax.value === currentTax;
      if (!isCurrentTax) {
        return false;
      }
    }
    return !tax.hide || tax.value === currentTax;
  });

  if (hasElectronicProvider) {
    return filteredOptions.filter((tax) => tax.type === TaxType.IVA);
  }

  return filteredOptions;
};

export const defaultTaxOption = taxOptions.IVA_21;

export const salesEqualizationTax = {
  [TaxKey.IVA_2]: 0.26,
  [TaxKey.IVA_4]: 0.5,
  [TaxKey.IVA_5]: 0.62,
  [TaxKey.IVA_7_5]: 1,
  [TaxKey.IVA_10]: 1.4,
  [TaxKey.IVA_21]: 5.2,
};

export interface TaxLine {
  base: number;
  tax: TaxOption;
  saleEqualization?: number;
  saleEqualizationAmount: number;
  taxAmount: number;
}

export const EuropeanNoSpainTaxKeys = [
  TaxKey.IVA_2_1, // France
  TaxKey.IVA_3, // Luxembourg
  TaxKey.IVA_4_8, // Ireland
  TaxKey.IVA_5_5, // France
  TaxKey.IVA_6, // Belgium, Greece, Portugal, Sweden
  TaxKey.IVA_7, // Germany, Malta
  TaxKey.IVA_8, // Luxembourg, Poland
  TaxKey.IVA_8_5, // France (Martinique, Guadeloupe, Réunion)
  TaxKey.IVA_9, // Bulgaria, Cyprus, Estonia, Ireland, Lithuania, Netherlands, Portugal (Azores), Romania
  TaxKey.IVA_9_5, // Slovenia
  TaxKey.IVA_12, // Belgium, Czech Republic, Latvia, Portugal (Madeira), Sweden
  TaxKey.IVA_13, // Austria, Croatia, France (Corsica), Greece, Portugal
  TaxKey.IVA_13_5, // Ireland
  TaxKey.IVA_14, // Finland, Luxembourg
  TaxKey.IVA_15, // Czech Republic
  TaxKey.IVA_16, // Portugal (Azores)
  TaxKey.IVA_17, // Greece (Aegean Islands), Luxembourg
  TaxKey.IVA_18, // Hungary, Malta
  TaxKey.IVA_19, // Austria (Jungholz, Mittelberg), Cyprus, Germany, Romania, Slovakia
  TaxKey.IVA_20, // Austria, Bulgaria, France, United Kingdom
  TaxKey.IVA_22, // Estonia, Italy, Portugal (Madeira), Slovenia
  TaxKey.IVA_23, // Ireland, Poland, Portugal, Slovakia
  TaxKey.IVA_24, // Greece
  TaxKey.IVA_25, // Croatia, Denmark, Sweden
  TaxKey.IVA_25_5, // Finland
  TaxKey.IVA_27, // Hungary
];

export const isEuropeanNoSpainTaxKey = (taxKey: TaxKey) =>
  EuropeanNoSpainTaxKeys.includes(taxKey);

export function isTaxKey(key: any): key is TaxKey {
  return Object.values(TaxKey).includes(key);
}
