import Description from '../Commons/Description';
import InputWrapper from '../Commons/InputWrapper';
import Label from '../Commons/Label';
import LabelText from '../Commons/LabelText';
import MaskedInput from '../Commons/MaskedInput';
import { FieldProps } from '../Field/Field';

export interface MaskedFieldProps extends FieldProps {
  mask: string;
  placeholderChar?: string;
  gridColumnStart?: {} | string;
}

const MaskedField = (props: MaskedFieldProps) => {
  const {
    className,
    disabled,
    error,
    flex,
    helpText,
    icon,
    id,
    label,
    labelHidden,
    margin,
    placeholderChar,
    maxLength,
    order,
    required,
    width = '100%',
    info,
    mask,
    height,
    size,
    gridArea,
    gridColumnStart,
    ...rest
  } = props;

  return (
    <Label
      className={className}
      flex={flex}
      gridArea={gridArea}
      gridColumnStart={gridColumnStart}
      htmlFor={id}
      id={id}
      margin={margin}
      order={order}
      width={width}
    >
      <LabelText
        disabled={disabled}
        hidden={labelHidden}
        id={`label-${id}`}
        info={info}
        required={required}
      >
        {label}
      </LabelText>
      <InputWrapper
        hasIcon={!!icon}
        {...{
          error,
          disabled,
        }}
      >
        <MaskedInput
          {...{
            disabled,
            error,
            helpText,
            id,
            maxLength,
            required,
            mask,
            placeholderChar,
            ...rest,
          }}
        />
        {icon}
      </InputWrapper>
      <Description id={`description-${id}`} hasError={!!error}>
        {error ?? helpText}
      </Description>
    </Label>
  );
};

export default MaskedField;
