const countries = [
  { label: 'Afganistán', value: 'AF' },
  { label: 'Albania', value: 'AL' },
  { label: 'Argelia', value: 'DZ' },
  { label: 'Samoa Americana', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguila', value: 'AI' },
  { label: 'Antártida', value: 'AQ' },
  { label: 'Antigua y Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaiyán', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrein', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarús', value: 'BY' },
  { label: 'Bélgica', value: 'BE' },
  { label: 'Belice', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermudas', value: 'BM' },
  { label: 'Bhután', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia y Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Isla Bouvet', value: 'BV' },
  { label: 'Brasil', value: 'BR' },
  { label: 'Territorio Británico del Océano Índico', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Camboya', value: 'KH' },
  { label: 'Camerún', value: 'CM' },
  { label: 'Canadá', value: 'CA' },
  { label: 'Cabo Verde', value: 'CV' },
  { label: 'Islas Caimán', value: 'KY' },
  { label: 'República Centroafricana', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Isla de Navidad', value: 'CX' },
  { label: 'Islas Cocos (Keeling)', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoras', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo (República Democrática del)', value: 'CD' },
  { label: 'Islas Cook', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Côte d'Ivoire", value: 'CI' },
  { label: 'Croacia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Chipre', value: 'CY' },
  { label: 'República Checa', value: 'CZ' },
  { label: 'Dinamarca', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'República Dominicana', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egipto', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Guinea Ecuatorial', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Etiopía', value: 'ET' },
  { label: 'Islas Malvinas', value: 'FK' },
  { label: 'Islas Feroe', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finlandia', value: 'FI' },
  { label: 'Francia', value: 'FR' },
  { label: 'Guayana Francesa', value: 'GF' },
  { label: 'Polinesia Francesa', value: 'PF' },
  { label: 'Tierras Australes Francesas', value: 'TF' },
  { label: 'Gabón', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Alemania', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Grecia', value: 'GR' },
  { label: 'Groenlandia', value: 'GL' },
  { label: 'Granada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haití', value: 'HT' },
  { label: 'Heard e Islas McDonald', value: 'HM' },
  { label: 'Santa Sede', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungría', value: 'HU' },
  { label: 'Islandia', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Irán (República Islámica de)', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Irlanda', value: 'IE' },
  { label: 'Irlanda del Norte', value: 'IX' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italia', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japón', value: 'JP' },
  { label: 'Jordania', value: 'JO' },
  { label: 'Kazajstán', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'República Popular Democrática de Corea', value: 'KP' },
  { label: 'República de Corea', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kirguistán', value: 'KG' },
  { label: 'República Democrática Popular de Lao', value: 'LA' },
  { label: 'Letonia', value: 'LV' },
  { label: 'Líbano', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libia', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lituania', value: 'LT' },
  { label: 'Luxemburgo', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malasia', value: 'MY' },
  { label: 'Maldivas', value: 'MV' },
  { label: 'Malí', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Islas Marshall', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauricio', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'México', value: 'MX' },
  { label: 'Micronesia', value: 'FM' },
  { label: 'Moldavia', value: 'MD' },
  { label: 'Mónaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Marruecos', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Países Bajos', value: 'NL' },
  { label: 'Nueva Caledonia', value: 'NC' },
  { label: 'Nueva Zelandia', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Níger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Isla Norfolk', value: 'NF' },
  { label: 'Isla Marianas del Norte', value: 'MP' },
  { label: 'Noruega', value: 'NO' },
  { label: 'Omán', value: 'OM' },
  { label: 'Pakistán', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestina', value: 'PS' },
  { label: 'Panamá', value: 'PA' },
  { label: 'Papua Nueva Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Perú', value: 'PE' },
  { label: 'Filipinas', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Polonia', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunión', value: 'RE' },
  { label: 'Rumania', value: 'RO' },
  { label: 'Rusia', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Santa Helena,} Ascensión y Tristán de Acuña', value: 'SH' },
  { label: 'Saint Kitts y Nevis', value: 'KN' },
  { label: 'Santa Lucía', value: 'LC' },
  { label: 'San Pedro y Miquelón', value: 'PM' },
  { label: 'San Vicente y las Granadinas', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Santo Tomé y Príncipe', value: 'ST' },
  { label: 'Arabia Saudita', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leona', value: 'SL' },
  { label: 'Singapur', value: 'SG' },
  { label: 'Eslovaquia', value: 'SK' },
  { label: 'Eslovenia', value: 'SI' },
  { label: 'Islas Salomón', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'Sudáfrica', value: 'ZA' },
  { label: 'Georgia del Sur y las Islas Sandwich del Sur', value: 'GS' },
  { label: 'España', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard y Jan Mayen', value: 'SJ' },
  { label: 'Swazilandia', value: 'SZ' },
  { label: 'Suecia', value: 'SE' },
  { label: 'Suiza', value: 'CH' },
  { label: 'República Árabe Siria', value: 'SY' },
  { label: 'Taiwán', value: 'TW' },
  { label: 'Tayikistán', value: 'TJ' },
  { label: 'Tanzania', value: 'TZ' },
  { label: 'Tailandia', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad y Tobago', value: 'TT' },
  { label: 'Túnez', value: 'TN' },
  { label: 'Turquía', value: 'TR' },
  { label: 'Turkmenistán', value: 'TM' },
  { label: 'Islas Turcas y Caicos', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ucrania', value: 'UA' },
  { label: 'Emiratos Árabes Unidos', value: 'AE' },
  { label: 'Reino Unido', value: 'GB' },
  { label: 'Estados Unidos', value: 'US' },
  { label: 'Islas Ultramarinas Menores de los Estados Unidos', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistán', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Islas Vírgenes británicas', value: 'VG' },
  { label: 'Islas Vírgenes de los Estados Unidos', value: 'VI' },
  { label: 'Wallis y Futuna', value: 'WF' },
  { label: 'Sahara Occidental', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
  { label: 'Islas Åland', value: 'AX' },
  { label: 'Bonaire,} San Eustaquio y Saba', value: 'BQ' },
  { label: 'Curaçao', value: 'CW' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Isla de Man', value: 'IM' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Saint Martin (francesa)', value: 'MF' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Sint Maarten (neerlandesa)', value: 'SX' },
  { label: 'Sudán del Sur', value: 'SS' },
  // { label: 'Kosovo', value: 'XK' },
];

export const europeanCountries = {
  DE: { name: 'Alemania', value: 'DE' },
  AT: { name: 'Austria', value: 'AT' },
  BE: { name: 'Bélgica', value: 'BE' },
  BG: { name: 'Bulgaria', value: 'BG' },
  CY: { name: 'Chipre', value: 'CY' },
  HR: { name: 'Croacia', value: 'HR' },
  DK: { name: 'Dinamarca', value: 'DK' },
  SI: { name: 'Eslovenia', value: 'SI' },
  ES: { name: 'España', value: 'ES' },
  EE: { name: 'Estonia', value: 'EE' },
  FI: { name: 'Finlandia', value: 'FI' },
  FR: { name: 'Francia', value: 'FR' },
  GR: { name: 'Grecia', value: 'GR' },
  HU: { name: 'Hungría', value: 'HU' },
  IE: { name: 'Irlanda', value: 'IE' },
  IT: { name: 'Italia', value: 'IT' },
  LV: { name: 'Letonia', value: 'LV' },
  LT: { name: 'Lituania', value: 'LT' },
  LU: { name: 'Luxemburgo', value: 'LU' },
  MT: { name: 'Malta', value: 'MT' },
  NL: { name: 'Países Bajos', value: 'NL' },
  PL: { name: 'Polonia', value: 'PL' },
  PT: { name: 'Portugal', value: 'PT' },
  CZ: { name: 'República Checa', value: 'CZ' },
  SK: { name: 'Eslovaquia', value: 'SK' },
  RO: { name: 'Rumania', value: 'RO' },
  SE: { name: 'Suecia', value: 'SE' },
};

export default countries;

export const DEFAULT_COUNTRY = 'ES';

export interface Country {
  name: string;
  value: string;
}
