import { FC, ReactNode } from 'react';

import styled from 'styled-components';
import {
  layout,
  LayoutProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  system,
} from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Box, CheckFormikField } from 'modules/Ui';

import messages from '../messages';

interface Props extends LayoutProps, GridTemplateColumnsProps {
  checkColumnEnd?: {} | string;
  checkColumnStart?: {} | string;
  children: ReactNode;
  columnGap?: {} | string;
  isEditing: boolean;
}

const StyleWrapper = styled(Box)<Props>`
  ${layout}
  ${gridTemplateColumns}
  ${system({
    columnGap: {
      property: 'columnGap',
      transform: (value) => `${value}`,
    },
  })}
`;

const SubFormBox: FC<Props> = ({
  checkColumnEnd,
  checkColumnStart,
  children,
  isEditing = false,
  ...rest
}) => {
  const { t } = useTranslations();
  return (
    <StyleWrapper
      backgroundColor="auxiliary100"
      boxSizing="border-box"
      fadeIn
      id="fieldset-payment-methods"
      margin="16px 0"
      padding="24px 16px"
      {...rest}
    >
      {children}
      {!isEditing && (
        <CheckFormikField
          data-testid="futureDocuments-check"
          gridColumnEnd={checkColumnEnd}
          gridColumnStart={checkColumnStart}
          id="futureDocuments"
          label={t(messages.futureDocuments)}
        />
      )}
    </StyleWrapper>
  );
};

export default SubFormBox;
