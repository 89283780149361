import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { DialogConfirm, useCloseModal } from 'modules/Modals';

import { cancelInvitationDialog } from './messages';
import { CancelUserInvitationModalProps } from './types';

const CancelUserInvitationModal: FC<CancelUserInvitationModalProps> = ({
  name,
  onSubmit,
}) => {
  const {
    t,
    formatHelpers: { bold },
  } = useTranslations();
  const closeModal = useCloseModal();

  const handleConfirm = () => {
    onSubmit();
    closeModal();
  };

  return (
    <DialogConfirm
      id="cancelUserInvitation"
      title={t(cancelInvitationDialog.title)}
      text={t(cancelInvitationDialog.text, { name, b: bold() })}
      confirmText={t(cancelInvitationDialog.button)}
      onConfirm={handleConfirm}
      hideSecondary
    />
  );
};

export default CancelUserInvitationModal;
