import { defaultTaxOption, TaxKey } from 'modules/Taxes';

import { LineType } from '../models/document';
import { TaxExemptions } from '../models/taxExemptions';

export const getDefaultTaxOption = (taxRegime: string) => {
  return taxRegime === '53' ? TaxKey.IVA_0 : defaultTaxOption.value;
};

const getDefaultTaxRegime = (taxRegime: string) => {
  if (taxRegime === '53') {
    return {
      taxExemptionCause: 'OT',
      taxExemptionGroup: TaxExemptions.NOT_SUBJECT,
    };
  }
  if (taxRegime === '02') {
    return {
      taxExemptionCause: 'E2',
      taxExemptionGroup: TaxExemptions.EXEMPTION,
    };
  }
  return {
    taxExemptionCause: 'E1',
    taxExemptionGroup: TaxExemptions.EXEMPTION,
  };
};

export const createDefaultConcept = (taxRegime: string) => {
  const taxKey = getDefaultTaxOption(taxRegime);
  return {
    id: Math.random().toString(),
    taxKey,
    name: '',
    quantity: 1,
    description: '',
    unitPrice: undefined,
    discountPercentage: undefined,
    taxExemption: getDefaultTaxRegime(taxRegime),
    groupName: '',
    order: 0,
    type: LineType.CONCEPT,
  };
};

export const createTitleConcept = () => ({
  type: LineType.TITLE,
  id: Math.random().toString(),
  text: '',
});

export const createUploadDefaultConcept = () => ({
  id: Math.random().toString(),
  name: '',
  description: '',
  taxKey: defaultTaxOption.value,
  unitPrice: 0,
  discountPercentage: 0,
  quantity: 1,
});

export const createUploadDefaultConceptPayload = () => ({
  name: 'upload-tax-0',
  description: '',
  taxKey: defaultTaxOption.value,
  unitPrice: 0,
  discountPercentage: 0,
  quantity: 1,
});
