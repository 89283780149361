import { FC, useMemo } from 'react';

import { Formik } from 'formik';

import { DigitLimits } from 'modules/App/constants';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { getPaymentOptions } from 'modules/Payments/helpers';
import {
  Box,
  DayPickerFormikField,
  FormikField,
  SelectFormikField,
} from 'modules/Ui';

import { PaymentMethodEnum, PaymentTypeEnum } from '../../models';
import AccountingAccountInput from '../AccountingAccountInput/AccountingAccountInput';
import messages from './messages';
import { getValidationSchema } from './validation';

interface DocumentPaymentFormProps {
  multiple?: boolean;
  maxAmount?: number;
  initialValues: FormPayment;
  handleSubmit(values: FormPayment): Promise<void>;
  onCreateAccount(values: FormPayment): void;
}

export interface FormPayment {
  amount?: number;
  method?: PaymentMethodEnum;
  operationDate: Date;
  accountingAccountId?: string;
  accountingAccountAlias?: string;
  type: PaymentTypeEnum;
}

const DocumentPaymentForm: FC<DocumentPaymentFormProps> = ({
  initialValues,
  handleSubmit,
  maxAmount,
  onCreateAccount,
  multiple,
}) => {
  const { t } = useTranslations();
  const validationSchema = getValidationSchema({ t, maxAmount, multiple });
  const paymentOptions = useMemo(() => getPaymentOptions(t), [t]);
  return (
    <Formik
      enableReinitialize={false}
      onSubmit={async (values, actions) => {
        await handleSubmit(values);
        actions.setSubmitting(false);
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
    >
      {(formik) => (
        <form
          onSubmit={formik.handleSubmit}
          id="update-payment-method"
          noValidate
        >
          <Box
            columnGap={{ _: '16px', sm: '24px' }}
            display="grid"
            gridTemplateColumns={{ sm: 'repeat(2, 1fr)' }}
            rowGap={{ _: '12px', sm: 'initial' }}
          >
            <DayPickerFormikField
              id="operationDate"
              label={t(messages.operationDate)}
              name="operationDate"
              required
            />
            <FormikField
              id="amount"
              label={t(messages.amount)}
              name={multiple ? 'amount-disabled' : 'amount'}
              type="number"
              disabled={multiple}
              disabledWithAsterisk={multiple}
              placeholder={multiple ? t(messages.pendingAmountPlaceholder) : ''}
              required
              decimalDigits={DigitLimits.DECIMAL_DIGITS}
              textAlign="right"
            />
            <SelectFormikField
              id="method"
              data-testid="method"
              isSearchable={false}
              label={t(messages.method)}
              name="method"
              placeholder={t(messages.methodPlaceholder)}
              options={paymentOptions}
            />
            <AccountingAccountInput
              onAddOption={() => {
                const values = formik.values ?? initialValues;
                onCreateAccount(values);
              }}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default DocumentPaymentForm;
