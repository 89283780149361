import { FC } from 'react';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { DialogConfirm, useCloseModal } from 'modules/Modals';

import useDocumentVoid from '../../hooks/useDocumentVoid';
import messages from './messages';
import { VoidDocumentModalProps } from './types';

const VoidDocumentModal: FC<VoidDocumentModalProps> = ({ document }) => {
  const {
    t,
    formatHelpers: { br },
  } = useTranslations();
  const { mutate: voidDocument } = useDocumentVoid();
  const closeModal = useCloseModal();

  const handleVoid = () => {
    voidDocument(document);
    closeModal();
  };

  return (
    <DialogConfirm
      onConfirm={handleVoid}
      title={t(messages.title)}
      subtitle={t(messages.subtitle)}
      text={t(messages.text, { br })}
      confirmText={t(messages.confirmText)}
    />
  );
};

export default VoidDocumentModal;
