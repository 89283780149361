import { Currency } from 'types/currency';
import { formatISO as formatDateTime } from 'utils/dates';

import { RecurrentType } from '../models/document';
import checkIfHasExemptionNotSubjectByIE from './checkIfHasExemptionNotSubjectByIE';
import { extractConceptsAndVisualLines } from './extractConceptsAndVisualLines';
import { processBizkaiaData } from './processBizkaiaData';
import { processConcepts } from './processConcepts';
import { processContact } from './processContact';
import { processExpenses } from './processExpenses';
import { processPaymentMethods } from './processPaymentMethods';
import { processSendInfo } from './processSendInfo';

export const normalizeRecurrentValues = (
  values: any,
  hasElectronicProvider?: boolean,
  recurrentType?: RecurrentType
) => {
  const {
    identifierName,
    additionalExpenses,
    autoSending,
    comments,
    concepts,
    contact,
    dueDocumentPeriod,
    hasAdditionalExpenses,
    hasPaidAmount,
    hasRetentions,
    hasSalesEqualizationTax,
    initDateTime: initDate,
    newDocumentPeriod,
    nextDateTime,
    operationDescription,
    paidAmount,
    paymentMethods,
    reference,
    retentionPercentage,
    sendInfo,
    serieId,
    showComments,
    taxRegime,
    templateId,
    currency,
    showProductReference,
    showLinesWithoutTaxes,
    type,
    file,
    hasFile,
    category,
    retentionAmount,
    total,
    hasAutoCalculate,
    hasInvoiceNumber,
    visualLines = [],
  } = values;

  const processedExpenses = processExpenses(
    hasAdditionalExpenses,
    additionalExpenses
  );

  const contactCountry = contact?.address?.country;
  const contactVatNumberType = contact?.vatNumberType;
  const hasTaxExemptionCause = checkIfHasExemptionNotSubjectByIE({
    contactCountry,
    contactVatNumberType,
  });
  const { onlyConcepts, onlyVisualLines } = extractConceptsAndVisualLines(
    concepts ?? [],
    visualLines
  );

  const processedConcepts = processConcepts(onlyConcepts, {
    hasElectronicProvider,
    hasAutoCalculate,
    hasTaxExemptionCause,
  });

  return {
    identifierName,
    newDocumentPeriod, // always comes with value
    dueDocumentPeriod, // always comes with value
    initDateTime: formatDateTime(initDate) || null,
    nextDateTime: formatDateTime(nextDateTime) || null,
    autoSending,
    sendInfo: processSendInfo({
      sendInfo,
      ccInput: values?.sendInfo?.ccInput,
      bccInput: values?.sendInfo?.bccInput,
      recaptcha: values?.sendInfo?.recaptcha,
    }),
    paymentMethodsAccepted: processPaymentMethods(paymentMethods),
    templateId,
    hasFile,
    file,
    documentTemplate: {
      ...(recurrentType === RecurrentType.EXPENSE && {
        totalFixedByClient: hasAutoCalculate
          ? null
          : {
              retentionAmount: retentionAmount || 0,
              total,
            },
      }),
      serieId:
        recurrentType === RecurrentType.EXPENSE && !hasInvoiceNumber
          ? null
          : serieId,
      currency: currency || Currency.EUR,
      comments: showComments ? comments || null : null,
      hasSalesEqualizationTax: hasSalesEqualizationTax || false,
      reference: reference?.trim().length ? reference : null,
      contact: processContact(contact) || null,
      retentionPercentage: hasRetentions ? retentionPercentage || 0 : 0,
      paidAmount: hasPaidAmount ? paidAmount : 0,
      additionalExpenses: processedExpenses,
      concepts: processedConcepts,
      visualLines: onlyVisualLines,
      showLinesWithoutTaxes,
      ...(hasElectronicProvider && {
        operationDescription,
        taxRegime,
        ...processBizkaiaData(values),
      }),
      requestId: values.requestId,
      showProductReference,
      type,
      category,
    },
  };
};
