import { ReactNode, useCallback, useState } from 'react';

import useTheme from 'modules/Theme/hooks/useTheme';
import { underlineHover } from 'modules/Theme/mixins';
import styled from 'modules/Theme/styled-components';
import { IconArrowSecondary } from 'modules/Ui/Icons';

import Box from '../../Box';
import Text from '../../Text';
import CustomExpandable from '../CustomExpandable';

interface Props {
  content: ReactNode;
  id?: string;
  initiallyExpanded?: boolean;
  isLastItem: boolean;
  onChange?(): void;
  open?: boolean;
  tag?: 'div' | 'li' | 'span';
  title: string | ReactNode;
}

const StyledFaq = styled(CustomExpandable)`
  .expandable {
    &__button {
      color: ${(props) => props.theme.colors.brand500};
      @media (hover: hover) {
        &:hover {
          color: ${(props) => props.theme.colors.gray800};
        }
      }

      &:focus {
        color: ${(props) => props.theme.colors.gray800};
      }
    }
    &__content {
      a {
       ${underlineHover};
      }
    }
  }


`;

const FaqsExpandable = ({
  content,
  id,
  initiallyExpanded = false,
  isLastItem,
  onChange = () => {},
  open,
  tag,
  title,
}: Props) => {
  const [stateExpanded, setExpanded] = useState(initiallyExpanded);
  const toggleExpandedState = useCallback(() => {
    setExpanded(!stateExpanded);
  }, [stateExpanded, setExpanded]);

  const expanded = open ?? stateExpanded;
  const toggleExpanded = open === undefined ? toggleExpandedState : onChange;
  const theme = useTheme();

  return (
    <Box tag={tag} id={id}>
      <StyledFaq
        initiallyExpanded={initiallyExpanded}
        id={id}
        title={
          <Box
            alignItems="center"
            borderBottom={
              !expanded && !isLastItem
                ? `1px solid  ${theme.colors.gray200}`
                : undefined
            }
            boxSizing="border-box"
            color="inherit"
            display="flex"
            justifyContent="space-between"
            onClick={toggleExpanded}
            padding={
              !expanded
                ? { _: '16px', sm: '20px 32px' }
                : { _: '16px 16px 11px', sm: '20px 32px 10px' }
            }
            tag="span"
            width="100%"
          >
            <Text
              color="inherit"
              fontSize={{ _: 16, sm: 18 }}
              lineHeight={{ _: 20, sm: 22 }}
              tag="strong"
              textAlign="left"
            >
              {title}
            </Text>
            <Box tag="span">
              <IconArrowSecondary
                color="brand500"
                size={16}
                transform={expanded ? 'rotate(-90deg)' : 'rotate(90deg)'}
              />
            </Box>
          </Box>
        }
        content={
          <Box
            borderBottom={
              expanded && !isLastItem && `1px solid ${theme.colors.gray200}`
            }
            padding={{ _: '0 16px 16px', sm: '0 32px 32px' }}
          >
            {content}
          </Box>
        }
      />
    </Box>
  );
};

export default FaqsExpandable;
