import { SubscriptionType } from 'modules/Subscriptions/cancelled/types';
import useIsAplifisa from 'utils/aplifisa/hooks/is-aplifisa';
import useIsAutonomosTelefonica from 'utils/autonomosTelefonica/hooks/is-autonomos-telefonica';
import useIsConversia from 'utils/conversia/hooks/is-conversia';
import useIsDelSol from 'utils/delsol/hooks/is-delsol';
import useIsOrbidi from 'utils/orbidi/hooks/is-orbidi';
import useIsTelefonica from 'utils/telefonica/hooks/is-telefonica';
import useIsVodafone from 'utils/vodafone/hooks/is-vodafone';

export const useGetPartnerName = (): SubscriptionType => {
  const isVodafone = useIsVodafone();
  const isTelefonica = useIsTelefonica();
  const isAutonomosTelefonica = useIsAutonomosTelefonica();
  const isDelSol = useIsDelSol();
  const isAplifisa = useIsAplifisa();
  const isConversia = useIsConversia();
  const isOrbidi = useIsOrbidi();
  if (isVodafone) {
    return 'vodafone';
  }
  if (isTelefonica) {
    return 'telefonica';
  }
  if (isAutonomosTelefonica) {
    return 'autonomos_telefonica';
  }
  if (isDelSol) {
    return 'delsol';
  }
  if (isAplifisa) {
    return 'aplifisa';
  }
  if (isConversia) {
    return 'conversia';
  }
  if (isOrbidi) {
    return 'orbidi';
  }
  return 'generic';
};
