import { FC } from 'react';

import styled from 'styled-components';
import { space, SpaceProps, background, BackgroundProps } from 'styled-system';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import { templateForm } from 'modules/Settings/messages';
import { ClipboardCopyWithPikito } from 'modules/Ui/ClipboardCopyWithPikito/ClipboardCopyWithPikito';

import Box from '../Box';

const StyledWrapper = styled(Box)`
  ${background}
  ${space}
`;

interface CopyDataPillProps extends SpaceProps, BackgroundProps {
  accessibleText?: string;
  backgroundColor?: string;
  iconHoverSize?: number;
  iconSize?: number;
  id: string;
}

export const CopyDataPill: FC<CopyDataPillProps> = ({
  accessibleText,
  backgroundColor,
  iconHoverSize,
  iconSize,
  id,
  ...rest
}) => {
  const { t } = useTranslations();
  return (
    <StyledWrapper
      alignItems="center"
      backgroundColor={backgroundColor || 'gray200'}
      borderRadius="19px"
      color="gray800"
      columnGap="4px"
      display="grid"
      fadeIn
      fontSize={14}
      gridTemplateColumns="1fr 24px"
      lineHeight={20}
      position="relative"
      width="fit-content"
      {...rest}
    >
      {id}
      <ClipboardCopyWithPikito
        accessibleText={accessibleText || t(templateForm.copy)}
        iconHoverSize={iconHoverSize || 32}
        iconSize={iconSize || 24}
        value={id}
      />
    </StyledWrapper>
  );
};

StyledWrapper.defaultProps = {
  padding: '2px 16px',
  marginBottom: '16px',
};
