import { layout, space, flexbox, gridArea, system } from 'styled-system';

import styled from 'modules/Theme/styled-components';

import Label from '../../Html/Label';

const CheckboxLabel = styled(Label)`
  ${space}
  ${flexbox}
  ${gridArea}
  display: flex;
  box-sizing: border-box;
  ${({ labelHidden }) =>
    labelHidden &&
    `--checkbox-Label-width: ${({ theme }: any) =>
      theme.spacing(3)}; overflow: hidden;`}
  @media (hover: hover) {
    &:hover {
      cursor: ${({ disabled }) => (disabled ? 'hand' : 'pointer')};
    }
  }
  ${layout}
  ${system({
    gridColumnStart: {
      property: 'gridColumnStart',
      transform: (value) => `${value}`,
    },
    gridColumnEnd: {
      property: 'gridColumnEnd',
      transform: (value) => `${value}`,
    },
  })}
`;

export default CheckboxLabel;
