export interface ActionPermissionParams {
  business: {
    canSendElectronicDocument: boolean;
    hasElectronicProvider: boolean;
  };
  user: {
    isEditor: boolean;
    isReader: boolean;
  };
  document: {
    canBeVoided: boolean;
    hasBaiVatNumberErrors: boolean;
    isCorrected: boolean;
    isCorrective: boolean;
    isDraft: boolean;
    isExpense: boolean;
    isFreezed: boolean;
    isNegative: boolean;
    isPaid: boolean;
    isPresented: boolean;
    isReceipt: boolean;
    isReplaced: boolean;
    isTest: boolean;
    isUploadFile: boolean;
    isVoid: boolean;
  };
}

export const canCopy = (options: ActionPermissionParams): boolean => {
  const {
    user: { isEditor },
    document: { isTest, isFreezed, isReceipt, isCorrective },
  } = options;
  return isEditor && !isTest && !isFreezed && !isReceipt && !isCorrective;
};

export const canDelete = (options: ActionPermissionParams): boolean => {
  const {
    business: { hasElectronicProvider },
    user: { isEditor },
    document: { isDraft, isExpense, isTest },
  } = options;
  const isElectronicProviderDeletableException = isDraft || isExpense || isTest;
  return (
    isEditor &&
    (!hasElectronicProvider || isElectronicProviderDeletableException)
  );
};

export const canDownloadElectronic = (
  options: ActionPermissionParams
): boolean => {
  const {
    user: { isEditor },
    document: {
      hasBaiVatNumberErrors,
      isCorrected,
      isCorrective,
      isFreezed,
      isDraft,
      isExpense,
      isTest,
      isUploadFile,
      isReceipt,
    },
  } = options;
  return (
    isEditor &&
    !hasBaiVatNumberErrors &&
    !isUploadFile &&
    !isExpense &&
    !isTest &&
    !isFreezed &&
    !isDraft &&
    !isReceipt &&
    !isCorrective &&
    !isCorrected
  );
};
export const canDownloadExcel = (options: ActionPermissionParams): boolean => {
  const {
    user: { isReader },
    document: { isDraft, isTest },
  } = options;
  return isReader && !isDraft && !isTest;
};

export const canDownloadDocument = (
  options: ActionPermissionParams
): boolean => {
  const {
    user: { isReader },
    document: { isTest },
  } = options;
  return isReader && !isTest;
};

export const canDownloadZip = (options: ActionPermissionParams): boolean => {
  const {
    user: { isReader },
    document: { isTest },
  } = options;
  return isReader && !isTest;
};

export const canEdit = (options: ActionPermissionParams): boolean => {
  const {
    business: { hasElectronicProvider },
    user: { isEditor },
    document: { isFreezed, isDraft, isExpense, isTest },
  } = options;
  const isElectronicProviderEditableException = isDraft || isExpense || isTest;
  return (
    isEditor &&
    !isFreezed &&
    (!hasElectronicProvider || isElectronicProviderEditableException)
  );
};

export const canEmitAsInvoice = (options: ActionPermissionParams): boolean => {
  const {
    user: { isEditor },
    document: { isReceipt, isPresented, isDraft, isReplaced },
  } = options;
  return isEditor && !isReceipt && !isPresented && isDraft && !isReplaced;
};

export const canEmitAsTicket = (options: ActionPermissionParams): boolean => {
  const {
    user: { isEditor },
    document: { isReceipt, isPresented, isDraft, isReplaced },
  } = options;
  return isEditor && isReceipt && !isPresented && isDraft && !isReplaced;
};

export const canMarkPaid = (options: ActionPermissionParams): boolean => {
  const {
    user: { isEditor },
    document: { isDraft, isVoid, isTest, isPaid },
  } = options;
  return isEditor && !isDraft && !isVoid && !isTest && !isPaid;
};

export const canPrint = (options: ActionPermissionParams): boolean => {
  const {
    user: { isEditor },
    document: { isReceipt, hasBaiVatNumberErrors },
  } = options;
  return isEditor && isReceipt && hasBaiVatNumberErrors;
};

export const canRectificate = (options: ActionPermissionParams): boolean => {
  const {
    business: { hasElectronicProvider },
    user: { isEditor },
    document: {
      hasBaiVatNumberErrors,
      isDraft,
      isExpense,
      isTest,
      isVoid,
      isCorrected,
    },
  } = options;
  return (
    isEditor &&
    isSendElectronicDocumentOk(options) &&
    !hasBaiVatNumberErrors &&
    hasElectronicProvider &&
    !isDraft &&
    !isExpense &&
    !isTest &&
    !isCorrected &&
    !isVoid
  );
};

export const canReplaceToInvoice = (
  options: ActionPermissionParams
): boolean => {
  const {
    user: { isEditor },
    document: { isDraft, isReceipt, isVoid, isReplaced },
  } = options;
  return (
    isEditor &&
    isReceipt &&
    !isDraft &&
    !isVoid &&
    !isReplaced &&
    isSendElectronicDocumentOk(options)
  );
};

export const canEmitDraftReceipt = (
  options: ActionPermissionParams
): boolean => {
  const {
    user: { isEditor },
    document: { isDraft, isReceipt, isCorrected, isTest },
  } = options;
  return isEditor && isDraft && isReceipt && !isCorrected && !isTest;
};

export const canSendDocument = (options: ActionPermissionParams): boolean => {
  const {
    user: { isReader },
    document: { isDraft, isReceipt, isFreezed, isTest },
  } = options;
  return isReader && !isDraft && !isReceipt && !isFreezed && !isTest;
};

export const canVoidDocument = (options: ActionPermissionParams): boolean => {
  const {
    business: { hasElectronicProvider },
    user: { isEditor },
    document: { isTest, isCorrective, canBeVoided },
  } = options;
  return (
    isEditor && hasElectronicProvider && !isTest && canBeVoided && !isCorrective
  );
};

const isSendElectronicDocumentOk = (
  options: ActionPermissionParams
): boolean => {
  const {
    business: { canSendElectronicDocument },
    document: { isPresented },
  } = options;
  return canSendElectronicDocument ? isPresented : true;
};

export const canCreateRemmittance = (
  options: ActionPermissionParams
): boolean => {
  const {
    user: { isEditor },
    document: {
      isTest,
      isReceipt,
      isPaid,
      isDraft,
      isCorrected,
      isNegative,
      isVoid,
    },
  } = options;
  return (
    isEditor &&
    !isCorrected &&
    !isReceipt &&
    !isDraft &&
    !isTest &&
    !isNegative &&
    !isPaid &&
    !isVoid
  );
};
