import { FC } from 'react';
import { useSelector } from 'react-redux';

import { space, SpaceProps, system, layout, LayoutProps } from 'styled-system';

import { selectIsMobile } from 'modules/App/store/selectors';
import styled from 'modules/Theme/styled-components';
import { Hr } from 'modules/Ui';

export interface SeparatorProps extends SpaceProps, LayoutProps {
  color?: string;
  gridColumnStart?: {} | string;
  gridColumnEnd?: {} | string;
  transform?: string;
}

const StyledSeparator = styled(Hr)<SeparatorProps>`
  ${space}
  ${layout}
  ${system({
    gridColumnStart: {
      property: 'gridColumnStart',
      transform: (value) => `${value}`,
    },
    gridColumnEnd: {
      property: 'gridColumnEnd',
      transform: (value) => `${value}`,
    },
  })}
`;

const Separator: FC<SeparatorProps> = ({ color, ...rest }) => {
  const isMobile = useSelector(selectIsMobile);
  return (
    <StyledSeparator
      color={color}
      transform={!isMobile ? 'translateX(-32px)' : undefined}
      {...rest}
    />
  );
};

StyledSeparator.defaultProps = {
  marginBottom: { _: '16px', sm: '32px' },
  width: { sm: 'calc(100% + 64px)' },
};

export default Separator;
