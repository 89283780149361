import {
  ConceptOrVisualLine,
  isConcept,
  Concept,
  VisualLine,
  isVisualLine,
} from '../models/document';

export const processVisualLines = (visualLine: VisualLine) => {
  const { id, ...rest } = visualLine;
  return { ...rest };
};

export const extractConceptsAndVisualLines = (
  concepts: ConceptOrVisualLine[],
  initialVisualLines: VisualLine[]
) => {
  const onlyConcepts: Concept[] = [];
  const onlyVisualLines: VisualLine[] = initialVisualLines;
  concepts?.forEach((concept: ConceptOrVisualLine, index: number) => {
    const orderedConcepts = { ...concept, order: index };
    if (isConcept(orderedConcepts)) {
      onlyConcepts.push(orderedConcepts);
    } else if (isVisualLine(orderedConcepts)) {
      onlyVisualLines.push(processVisualLines(orderedConcepts));
    }
  });
  return { onlyConcepts, onlyVisualLines };
};
