import { AdditionalExpense } from '../models/document';

export function processExpenses(
  hasAdditionalExpenses?: boolean,
  additionalExpenses?: AdditionalExpense[]
) {
  if (!hasAdditionalExpenses || !additionalExpenses) {
    return [];
  }

  return additionalExpenses
    .map((e) => ({
      expense: e.expense,
      description: e.description,
    }))
    .filter(({ expense }) => expense != null);
}
