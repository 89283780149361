import { useCallback, useMemo, FC } from 'react';
import { Hidden } from 'react-grid-system';

import { Formik } from 'formik';

import { DocumentSendInfo } from 'modules/Documents/models/document';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { Dialog, MODAL_WIDTH_MEDIUM, useCloseModal } from 'modules/Modals';
import { Button } from 'modules/Ui';

import {
  processEmailContent,
  processHtmlEmailContent,
} from '../../helpers/helpers';
import getValidationSchema from '../../helpers/validation';
import Form from './Form';
import messages from './messages';
import { RecurrentSendingProps } from './types';

const getInitialValues = (
  defaultInitialValues: DocumentSendInfo,
  initialValues?: DocumentSendInfo
) => {
  if (!initialValues) {
    return defaultInitialValues;
  }

  return {
    ...initialValues,
    content: processHtmlEmailContent(initialValues?.content),
  };
};

const ConfigRecurrentSendingModal: FC<RecurrentSendingProps> = ({
  initialValues,
  customerName,
  userName,
  to,
  cc,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslations();
  const defaultInitialValues = {
    to: to || '',
    cc: cc || [],
    bcc: [],
    subject: t(messages.subject, { name: userName }),
    content: t(messages.contentPlaceholder, { name: customerName }),
    showDetails: false,
  };

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const closeModal = useCloseModal();

  const cancelHandler = useCallback(() => {
    onCancel();
    closeModal();
  }, [onCancel, closeModal]);

  return (
    <Dialog
      id="config-recurrent-sending-modal"
      title={t(messages.title)}
      type="fullScreen"
      dialogWidth={{ md: MODAL_WIDTH_MEDIUM }}
      buttonPrimary={
        <Button
          data-testid="config-recurrent-sending-submit"
          form="config-send-recurrent-form"
          type="submit"
          variant="secondary"
        >
          {t(messages.button)}
        </Button>
      }
      buttonSecondary={
        <Hidden xs>
          <Button onClick={cancelHandler} variant="stroke">
            {t(messages.cancel)}
          </Button>
        </Hidden>
      }
      onClose={cancelHandler}
    >
      <Formik<DocumentSendInfo>
        {...{
          validationSchema,
          initialValues: getInitialValues(defaultInitialValues, initialValues),
          validateOnChange: false,
          validateOnBlur: false,
          onSubmit: async (values, actions) => {
            onSubmit({
              ...values,
              content: processEmailContent(values.content),
            });
            actions.setSubmitting(false);
            closeModal();
          },
        }}
      >
        {(formik) => (
          <>
            <Form {...{ formik, customerName, userName }} />
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default ConfigRecurrentSendingModal;
