import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Formik } from 'formik';

import { selectIsMobile } from 'modules/App/store/selectors';
import { MAX_CHARACTERS_CONTENT_SEND } from 'modules/Documents/Send/constants';
import { SuggestionsFields } from 'modules/Documents/Send/hooks/useSuggestionsData';
import useTranslations from 'modules/I18n/hooks/useTranslations';
import { useCloseModal } from 'modules/Modals';
import { Box, Fieldset, SuggestionsFormikField, Text } from 'modules/Ui';
import { preventDefaultOnEnterKeyPress } from 'utils/events';

import { messages } from '../messages';
import { ToneEnum } from '../models/paymentReminder';
import { angryContent, formalContent, friendlyContent } from './content';
import { DialogExpandable } from './DialogExpandable';
import { ReminderDaysForm } from './ReminderDaysForm';
import { ToneField } from './ToneField';
import getValidationSchema from './validation';

export interface PaymentReminderFormProps {
  formId: string;
  onSubmit: (values: FormValues) => void;
  formValues: FormValues;
}

export interface FormValues {
  tone: ToneEnum;
  when: string;
  subject: string;
  body: string;
  days?: number;
}

const PaymentReminderForm: FC<PaymentReminderFormProps> = ({
  formId,
  onSubmit,
  formValues,
}) => {
  const closeModal = useCloseModal();
  const isMobile = useSelector(selectIsMobile);

  const { t } = useTranslations();

  const handleSubmit = (values: FormValues) => {
    onSubmit(values);
    closeModal();
  };

  const onKeyPress = useCallback(preventDefaultOnEnterKeyPress, []);

  const suggestionsData = [
    {
      id: SuggestionsFields.NombreCliente,
      display: SuggestionsFields.NombreCliente,
    },
    {
      id: SuggestionsFields.NumeroDocumento,
      display: SuggestionsFields.NumeroDocumento,
    },
    {
      id: SuggestionsFields.NombreFiscalEmisor,
      display: SuggestionsFields.NombreFiscalEmisor,
    },
    {
      id: SuggestionsFields.FechaVencimiento,
      display: SuggestionsFields.FechaVencimiento,
    },
  ];

  return (
    <Formik<FormValues>
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={formValues}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={getValidationSchema(t)}
    >
      {(formik) => (
        <>
          <Box
            autoComplete="off"
            id={formId}
            noValidate
            onSubmit={formik.handleSubmit}
            tag="form"
          >
            <ReminderDaysForm />
            <Fieldset
              columnGap="8px"
              display="grid"
              gridTemplateColumns={{
                _: 'repeat(3, 1fr)',
                sm: '104px 104px 104px 1fr',
              }}
              id="reminder-days-tones"
              legend={t(messages.chooseTone)}
              legendFontSize={16}
              legendFontWeight="initial"
              legendGridColumnEnd="5"
              legendGridColumnStart="1"
              marginBottom={{ _: '32px', sm: '16px' }}
              uppercase={false}
            >
              <ToneField
                text={t(messages.toneFriendly)}
                value={ToneEnum.FRIENDLY}
                onChange={() => formik.setFieldValue('body', friendlyContent)}
                id="friendly-tone"
              >
                <Text
                  aria-hidden="true"
                  display="block"
                  fontSize={32}
                  lineHeight={70}
                  tag="span"
                  textAlign="center"
                >
                  😊
                </Text>
              </ToneField>

              <ToneField
                text={t(messages.toneFormal)}
                value={ToneEnum.FORMAL}
                id="formal-tone"
                onChange={() => formik.setFieldValue('body', formalContent)}
              >
                <Text
                  aria-hidden="true"
                  display="block"
                  fontSize={32}
                  lineHeight={70}
                  tag="span"
                  textAlign="center"
                >
                  🙁
                </Text>
              </ToneField>

              <ToneField
                value={ToneEnum.ANGRY}
                onChange={() => formik.setFieldValue('body', angryContent)}
                id="angry-tone"
                text={t(messages.toneAngry)}
              >
                <Text
                  aria-hidden="true"
                  display="block"
                  fontSize={32}
                  lineHeight={70}
                  tag="span"
                  textAlign="center"
                >
                  😤
                </Text>
              </ToneField>
            </Fieldset>

            <SuggestionsFormikField
              id="subject"
              data-testid="subject"
              label="Asunto"
              name="subject"
              onKeyPress={onKeyPress}
              maxLength={200}
              data={suggestionsData}
              singleLine={!isMobile}
              required
            />
            <SuggestionsFormikField
              label="Cuerpo de email"
              id="body"
              name="body"
              maxHeight="200px"
              maxLength={MAX_CHARACTERS_CONTENT_SEND}
              data={suggestionsData}
              required
            />
          </Box>
          <DialogExpandable />
        </>
      )}
    </Formik>
  );
};

export default PaymentReminderForm;
