import React from 'react';
import { useSelector } from 'react-redux';

import {
  AccountingAccountModal,
  UpdateAccountingAccountModal,
} from 'modules/AccountingAccounts/components';
import { AccountDeleteModal } from 'modules/AccountingAccounts/components/UpdateAccountingAccountModal/components';
import CancelInvitationModal from 'modules/Bookkeeper/components/CancelInvitationModal';
import DeleteClientModal from 'modules/Bookkeeper/components/DeleteClientModal';
import ResendInvitationModal from 'modules/Bookkeeper/components/ResendInvitationModal';
import AddSalesCodeModal from 'modules/Bookkeeper/Exporter/components/AddSalesCodeModal';
import ExporterHelpModal from 'modules/Bookkeeper/Exporter/components/ExporterHelp/components/ExporterHelpModal';
import SelectCustomerDialog from 'modules/Bookkeeper/Exporter/components/SelectCustomerDialog/Loadable';
import ChangeBusinessModal from 'modules/Business/Change/Change';
import ChangeDataBusinessModal from 'modules/Business/components/ChangeDataBusinessModal';
import BusinessDeleteModal from 'modules/Business/Delete/BusinessDeleteModal/Loadable';
import CancelUserInvitationModal from 'modules/BusinessUsers/ChangeUserRole/CancelUserInvitationModal';
import UsersDeleteModal from 'modules/BusinessUsers/UsersDeleteModal/Loadable';
import LimitContactsCreateDialog from 'modules/Contacts/Create/components/Loadable';
import CreateContactDialog from 'modules/Contacts/CreateContactDialog/Loadable';
import ContactsDeleteModal from 'modules/Contacts/Delete/components/ContactsDeleteModal/Loadable';
import SearchContactDialog from 'modules/Contacts/SearchContactDialog/Loadable';
import ConfirmBaiSubmissionModal from 'modules/Documents/BaiSubmission/components/ConfirmBaiSubmissionModal/Loadable';
import BaiErrorsModal from 'modules/Documents/components/BaiErrorsModal/BaiErrorsModal';
import CompleteValidVatNumbers from 'modules/Documents/components/CompleteValidVatNumbers/Loadable';
import CreateRecurrentErrorModal from 'modules/Documents/components/CreateRecurrentErrorModal/CreateRecurrentErrorModal';
import CreateSerieModal from 'modules/Documents/components/CreateSerieModal/Loadable';
import AddPaymentMethodModal from 'modules/Documents/components/Form/AddPaymentMethodModal/AddPaymentMethodModal';
import UpdatePaymentMethodModal from 'modules/Documents/components/Form/AddPaymentMethodModal/UpdatePaymentMethodModal';
import DetachProductModal from 'modules/Documents/components/Form/components/DetachProductModal/Loadable';
import ChangeVatNumberModal from 'modules/Documents/components/TwoStepsForm/FirstStep/components/TicketBaiOptions/ChangeVatNumberModal/ChangeVatNumberModal';
import TaxRegimeWarningModal from 'modules/Documents/components/TwoStepsForm/FirstStep/components/TicketBaiOptions/TaxRegimeWarningModal';
import DocumentsDeleteModal from 'modules/Documents/Delete/components/DocumentsDeleteModal/Loadable';
import DocumentRectifyModal from 'modules/Documents/Detail/components/DocumentRecitifyModal/DocumentRectifyModal';
import DocumentTrackingMobileModal from 'modules/Documents/Detail/components/DocumentTrackingMobileModal';
import DocumentTrackingModal from 'modules/Documents/Detail/components/DocumentTrackingModal';
import DataElectronicModal from 'modules/Documents/Electronic/components/DataElectronicModal/DataElectronicModal';
import DocumentsDownloadZipModal from 'modules/Documents/List/components/DocumentsDownloadZipModal/DocumentsDownloadZipModal';
import IssueDeliveryNoteDialog from 'modules/Documents/List/components/IssueDeliveryNoteDialog/Loadable';
import ActionsIsReceiptModal from 'modules/Documents/List/components/Modals/ActionsIsReceiptModal';
import SortOutExpensesRecurrentsModal from 'modules/Documents/List/components/SortOutExpensesModal/SortOutExpenseRecurrentModal';
import SortOutExpensesDocumentsModal from 'modules/Documents/List/components/SortOutExpensesModal/SortOutExpensesDocumentsModal';
import TestInvoiceModal from 'modules/Documents/List/components/TestInvoiceModal';
import ConfigRecurrentSendingModal from 'modules/Documents/Send/components/ConfigRecurrentSendingModal/ConfigRecurrentSendingModal';
import SuggestionsInfoModal from 'modules/Documents/Send/components/SuggestionsInfoModal/Loadable';
import DeleteMultiplePendingDocumentsModal from 'modules/Documents/Upload/components/DeleteMultiplePendingDocumentsModal/DeleteMultiplePendingDocumentsModal';
import DeletePendingDocumentModal from 'modules/Documents/Upload/components/DeletePendingDocumentModal/DeletePendingDocumentModal';
import OcrInfoModal from 'modules/Documents/Upload/components/Form/OcrInfoModal/OcrInfoModal';
import NoDocumentForScanModal from 'modules/Documents/Upload/components/NoDocumentForScanModal';
import ScanLimitModal from 'modules/Documents/Upload/components/ScanLimitModal';
import ScanningDocumentModal from 'modules/Documents/Upload/components/ScanningDocumentModal/ScanningDocumentModal';
import UpgradeForMoreScansModal from 'modules/Documents/Upload/components/UpgradeForMoreScansModal';
import VoidDocumentModal from 'modules/Documents/Void/components/VoidDocumentModal/VoidDocumentModal';
import CreateRecurrentPlanErrorModal from 'modules/Expenses/components/CreateRecurrent/CreateRecurrentPlanErrorModal';
import LoadSavedDataModal from 'modules/Forms/components/LoadSavedDataModal/Loadable';
import { DeactivateShopifyWarningModal } from 'modules/Integrations/components';
import DeleteCredentialModal from 'modules/Integrations/components/ApiConfigurationForm/DeleteCredentialModal/Loadable';
import GenerateCredentialsModal from 'modules/Integrations/components/ApiConfigurationForm/GenerateCredentialModal/Loadable';
import { DialogConfirm } from 'modules/Modals/components';
import OldAppModal from 'modules/OldApp';
import AddPaymentReminderModal from 'modules/PaymentReminders/components/AddPaymentReminderModal/AddPaymentReminderModal';
import { DeletePaymentReminderModal } from 'modules/PaymentReminders/components/DeletePaymentReminderModal';
import EditPaymentReminderModal from 'modules/PaymentReminders/components/EditPaymentReminderModal/EditPaymentReminderModal';
import CreateDocumentPaymentModal from 'modules/Payments/components/CreateDocumentPaymentModal/CreateDocumentPaymentModal';
import { CreatePaymentModal } from 'modules/Payments/components/CreatePaymentModal';
import EditPaymentModal from 'modules/Payments/components/EditPaymentModal/EditPaymentModal';
import DeletePaymentsModal from 'modules/Payments/components/List/components/DeletePaymentsModal/DeletePaymentsModal';
import AddProductDialog from 'modules/Products/AddProductDialog/Loadable';
import AddProductFromDocumentDialog from 'modules/Products/AddProductFromDocumentDialog/Loadable';
import AddProductPlanErrorModal from 'modules/Products/components/AddProductPlanErrorModal/Loadable';
import DeactivateImagesOnProductsModal from 'modules/Products/components/DeactivateImagesOnProducts/DeactivateImagesOnProductsModal';
import DeactivateStockModal from 'modules/Products/components/DeactivateStockModal/Loadable';
import ChangeUsedProductModal from 'modules/Products/components/Form/ChangeUsedProductModal/Loadable';
import ProductsDeleteModal from 'modules/Products/Delete/components/ProductsDeleteModal/Loadable';
import { ProductsZeroStateDialog } from 'modules/Products/ProductsZeroStateDialog';
import AddContactModal from 'modules/Quotes/components/AddContactModal/AddContactModal';
import { RemittanceContactModal } from 'modules/Remittances/CreateRemittanceAddDocuments';
import DeleteRemittanceModal from 'modules/Remittances/DeleteRemittances/components/DeleteRemittanceModal/Loadable';
import RemittanceDocumentsDeleteModal from 'modules/Remittances/Detail/components/RemittanceDocumentsDeleteModal/RemittanceDocumentsDeleteModal';
import DocumentsAlreadyInARemittanceModal from 'modules/Remittances/DocumentsAlreadyInARemittanceModal/DocumentsAlreadyInARemittanceModal';
import AddRemittancePlanErrorModal from 'modules/Remittances/PlanErrorModal/AddRemittancePlanErrorModal';
import {
  AddNewRemittanceModal,
  SetRemittanceStatusModal,
  EditRemittanceModal,
} from 'modules/Remittances/RemittanceList';
import BaiConnectionModal from 'modules/Settings/components/BAIForm/BaiConnectionModal';
import SaveChangesOptionsModal from 'modules/Settings/components/SaveChangesOptionsModal/Loadable';
import PaymentsDeleteModal from 'modules/Settings/Delete/components/PaymentsDeleteModal/Loadable';
import SeriesDeleteErrorModal from 'modules/Settings/Delete/components/SeriesDeleteErrorModal/Loadable';
import SeriesDeleteModal from 'modules/Settings/Delete/components/SeriesDeleteModal/Loadable';
import ChangePlanErrorModal from 'modules/Subscriptions/Change/ChangePlanErrorModal';
import ChangePlanWarningModal from 'modules/Subscriptions/Change/ChangePlanWarningModal';
import CreatePlanErrorModal from 'modules/Subscriptions/Create/components/CreatePlanErrorModal/CreatePlanErrorModal';
import CreatePlanWarningModal from 'modules/Subscriptions/Create/components/CreatePlanWarningModal/CreatePlanWarningModal';
import CancelSubscriptionModal from 'modules/Subscriptions/detail/components/CancelSubscription/CancelSubscriptionModal';
import ChangeCardModal from 'modules/Subscriptions/detail/components/ChangeCard/ChangeCardModal';
import ChangeBillingEmailModal from 'modules/Subscriptions/detail/components/ChangeEmail/ChangeEmailLogic';
import InfoModal from 'modules/Ui/InfoModal';
import { PdfViewer, TemplateViewer } from 'modules/Ui/PdfViewer';
import ChangePasswordModal from 'modules/Users/ChangePassword/ChangePasswordModal';
import CurrentUserDeleteModal from 'modules/Users/CurrentUserDeleteModal/Loadable';
import SelectLanguageModal from 'modules/Users/SelectLanguageModal/Loadable';

import FixVatNumberModal from '../Documents/components/FixVatNumberModal/FixVatNumberModal';
import InvalidTaxRegimeModal from '../Documents/components/InvalidTaxRegimeModal/InvalidTaxRegimeModal';
import BaiFixVatNumberModal from '../Documents/FixDocument/components/BaiFixVatNumberModal/BaiFixVatNumberModal';
import MultipleZipsDownloadModal from '../Documents/List/components/DocumentsDownloadZipModal/MultipleZipsDownloadModal';
import ConfigureShopifyModal from '../Home/components/ConfigureShopifyModal';
import { ModalTypes } from './constants';
import useAnalyticsModals from './hooks/useAnalyticsModals';
import { selectProps } from './selectors';

const MODAL_COMPONENTS = {
  [ModalTypes.ACCOUNTING_ACCOUNT]: AccountingAccountModal,
  [ModalTypes.ACTIONS_ISRECEIPT_MODAL]: ActionsIsReceiptModal,
  [ModalTypes.ADD_CONTACT]: AddContactModal,
  [ModalTypes.ADD_PAYMENT_METHOD]: AddPaymentMethodModal,
  [ModalTypes.ADD_PRODUCT_TO_DOCUMENT]: AddProductDialog,
  [ModalTypes.ADD_PRODUCT_FROM_DOCUMENT]: AddProductFromDocumentDialog,
  [ModalTypes.ADD_REMITTANCE_MODAL]: AddNewRemittanceModal,
  [ModalTypes.ADD_REMITTANCE_PLAN_ERROR_MODAL]: AddRemittancePlanErrorModal,
  [ModalTypes.ADD_PRODUCT_PLAN_ERROR_MODAL]: AddProductPlanErrorModal,
  [ModalTypes.ADD_SALES_MODAL]: AddSalesCodeModal,
  [ModalTypes.BAI_CONNECTION]: BaiConnectionModal,
  [ModalTypes.BAI_VALIDATION_ERRORS]: BaiErrorsModal,
  [ModalTypes.BOOKKEEPER_DELETE_CLIENT_MODAL]: DeleteClientModal,
  [ModalTypes.BOOKKEEPER_SELECT_CUSTOMER]: SelectCustomerDialog,
  [ModalTypes.CANCEL_INVITATION_MODAL]: CancelInvitationModal,
  [ModalTypes.CANCEL_USER_INVITATION_MODAL]: CancelUserInvitationModal,
  [ModalTypes.CHANGE_BAI_VAT_NUMBER]: ChangeVatNumberModal,
  [ModalTypes.CHANGE_BILLING_EMAIL]: ChangeBillingEmailModal,
  [ModalTypes.CHANGE_BUSINESS]: ChangeBusinessModal,
  [ModalTypes.CHANGE_CARD]: ChangeCardModal,
  [ModalTypes.CHANGE_DATA_BUSINESS]: ChangeDataBusinessModal,
  [ModalTypes.CHANGE_PASSWORD]: ChangePasswordModal,
  [ModalTypes.CHANGE_PLAN_ERROR_MODAL]: ChangePlanErrorModal,
  [ModalTypes.CHANGE_PLAN_WARNING_MODAL]: ChangePlanWarningModal,
  [ModalTypes.SET_REMITTANCE_STATUS_MODAL]: SetRemittanceStatusModal,
  [ModalTypes.COMPLETE_VALID_VAT_NUMBERS]: CompleteValidVatNumbers,
  [ModalTypes.CONFIG_RECURRENT_SENDING_MODAL]: ConfigRecurrentSendingModal,
  [ModalTypes.CONFIGURE_SHOPIFY]: ConfigureShopifyModal,
  [ModalTypes.CONFIRM_BAI_SUBMISSION]: ConfirmBaiSubmissionModal,
  [ModalTypes.CONFIRMATION_DIALOG]: DialogConfirm,
  [ModalTypes.CREATE_CONTACT]: CreateContactDialog,
  [ModalTypes.CREATE_DOCUMENT_PAYMENT]: CreateDocumentPaymentModal,
  [ModalTypes.CREATE_PAYMENT]: CreatePaymentModal,
  [ModalTypes.CREATE_PLAN_ERROR]: CreatePlanErrorModal,
  [ModalTypes.CREATE_PLAN_WARNING]: CreatePlanWarningModal,
  [ModalTypes.CREATE_RECURRENT_ERROR_MODAL]: CreateRecurrentErrorModal,
  [ModalTypes.CREATE_SERIE_MODAL]: CreateSerieModal,
  [ModalTypes.CUSTOMIZE_TEMPLATE_PREVIEW]: TemplateViewer,
  [ModalTypes.DATA_ELECTRONIC]: DataElectronicModal,
  [ModalTypes.DEACTIVATE_SHOPIFY_WARNING]: DeactivateShopifyWarningModal,
  [ModalTypes.DELETE_ACCOUNT]: AccountDeleteModal,
  [ModalTypes.DELETE_BUSINESS]: BusinessDeleteModal,
  [ModalTypes.DELETE_CONTACTS]: ContactsDeleteModal,
  [ModalTypes.DELETE_CREDENTIAL]: DeleteCredentialModal,
  [ModalTypes.DELETE_DOCUMENT_SERIE_ERROR]: SeriesDeleteErrorModal,
  [ModalTypes.DELETE_DOCUMENT_SERIE]: SeriesDeleteModal,
  [ModalTypes.DELETE_DOCUMENTS]: DocumentsDeleteModal,
  [ModalTypes.DELETE_ME]: CurrentUserDeleteModal,
  [ModalTypes.DELETE_MULTIPLE_REVIEW_PENDING_DOCUMENT]:
    DeleteMultiplePendingDocumentsModal,
  [ModalTypes.DELETE_PAYMENT_METHOD]: PaymentsDeleteModal,
  [ModalTypes.DELETE_PAYMENTS]: DeletePaymentsModal,
  [ModalTypes.DELETE_PRODUCTS]: ProductsDeleteModal,
  [ModalTypes.DELETE_REVIEW_PENDING_DOCUMENT]: DeletePendingDocumentModal,
  [ModalTypes.DELETE_SUSCRIPTION]: CancelSubscriptionModal,
  [ModalTypes.DELETE_USER]: UsersDeleteModal,
  [ModalTypes.DOCUMENT_TRACKING_MOBILE]: DocumentTrackingMobileModal,
  [ModalTypes.DOCUMENT_TRACKING]: DocumentTrackingModal,
  [ModalTypes.DOWNLOAD_DOCUMENTS_ZIP]: DocumentsDownloadZipModal,
  [ModalTypes.EDIT_PAYMENT]: EditPaymentModal,
  [ModalTypes.EXPORTER_HELP_MODAL]: ExporterHelpModal,
  [ModalTypes.FIX_BAI_VAT_NUMBER]: BaiFixVatNumberModal,
  [ModalTypes.FIX_VAT_NUMBER_MODAL]: FixVatNumberModal,
  [ModalTypes.GENERATE_CREDENTIALS]: GenerateCredentialsModal,
  [ModalTypes.INFORMATION_MODAL]: InfoModal,
  [ModalTypes.INVALID_TAX_REGIME_MODAL]: InvalidTaxRegimeModal,
  [ModalTypes.ISSUE_DELIVERY_NOTE]: IssueDeliveryNoteDialog,
  [ModalTypes.LIMIT_CONTACTS]: LimitContactsCreateDialog,
  [ModalTypes.MULTIPLE_ZIPS_DOWNLOAD]: MultipleZipsDownloadModal,
  [ModalTypes.NO_DOCUMENT_FOR_SCAN]: NoDocumentForScanModal,
  [ModalTypes.OCR_INFO]: OcrInfoModal,
  [ModalTypes.OLD_APP_MODAL]: OldAppModal,
  [ModalTypes.PDF_PREVIEW]: PdfViewer,
  [ModalTypes.RECTIFY_DOCUMENT]: DocumentRectifyModal,
  [ModalTypes.REMITTANCE_CONTACT_MODAL]: RemittanceContactModal,
  [ModalTypes.RESEND_INVITATION_MODAL]: ResendInvitationModal,
  [ModalTypes.SAVE_CHANGES_OPTIONS]: SaveChangesOptionsModal,
  [ModalTypes.SCAN_LIMIT_MODAL]: ScanLimitModal,
  [ModalTypes.SCANNING_DOCUMENT]: ScanningDocumentModal,
  [ModalTypes.SEARCH_CONTACTS]: SearchContactDialog,
  [ModalTypes.SELECT_LANGUAGE]: SelectLanguageModal,
  [ModalTypes.SORT_OUT_EXPENSES_DOCUMENTS]: SortOutExpensesDocumentsModal,
  [ModalTypes.SORT_OUT_EXPENSES_RECURRENTS]: SortOutExpensesRecurrentsModal,
  [ModalTypes.SUGGESTION_INFO_MODAL]: SuggestionsInfoModal,
  [ModalTypes.TAX_REGIME_MODAL]: TaxRegimeWarningModal,
  [ModalTypes.TEST_INVOICE]: TestInvoiceModal,
  [ModalTypes.UPDATE_ACCOUNTING_ACCOUNT]: UpdateAccountingAccountModal,
  [ModalTypes.UPDATE_PAYMENT_METHOD]: UpdatePaymentMethodModal,
  [ModalTypes.UPGRADE_FOR_MORE_SCANS]: UpgradeForMoreScansModal,
  [ModalTypes.VOID_DOCUMENT]: VoidDocumentModal,
  [ModalTypes.DELETE_REMITTANCES]: DeleteRemittanceModal,
  [ModalTypes.EDIT_REMITTANCE_MODAL]: EditRemittanceModal,
  [ModalTypes.DELETE_REMITTANCE_DOCUMENTS]: RemittanceDocumentsDeleteModal,
  [ModalTypes.DOCUMENTS_ALREADY_IN_REMITTANCE]:
    DocumentsAlreadyInARemittanceModal,
  [ModalTypes.CHANGE_USED_PRODUCT]: ChangeUsedProductModal,
  [ModalTypes.DETACH_PRODUCT]: DetachProductModal,
  [ModalTypes.PRODUCT_ZERO_STATE_DIALOG]: ProductsZeroStateDialog,
  [ModalTypes.RECURRENT_PLAN_ERROR]: CreateRecurrentPlanErrorModal,
  [ModalTypes.LOAD_SAVED_FORM_DATA]: LoadSavedDataModal,
  [ModalTypes.DEACTIVATE_STOCK]: DeactivateStockModal,
  [ModalTypes.DEACTIVATE_IMAGES_ON_PRODUCTS]: DeactivateImagesOnProductsModal,
  [ModalTypes.ADD_PAYMENT_REMINDER]: AddPaymentReminderModal,
  [ModalTypes.EDIT_PAYMENT_REMINDER]: EditPaymentReminderModal,
  [ModalTypes.DELETE_PAYMENT_REMINDER]: DeletePaymentReminderModal,
  CreateRecurrentPlanErrorModal,
};

const Modals = () => {
  const { type, ...props } = useSelector(selectProps);

  useAnalyticsModals();

  if (!type) {
    return null;
  }

  const SpecificModal: React.FC<any> = MODAL_COMPONENTS[type];

  return <SpecificModal {...props} />;
};

export default Modals;
