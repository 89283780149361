import { CaptchaProvider } from 'modules/Captcha/models';

import { AppConfig } from './models';

const defaultConfig: AppConfig = {
  host: 'app.billin.net',
  apiDomain: process.env.REACT_APP_API_DOMAIN || '',
  apiPantumacaDomain: process.env.REACT_APP_API_PANTUMACA_DOMAIN || '',
  apiAsyncTaskService: process.env.REACT_APP_API_ASYNC_TASK_SERVICE || '',
  apiAuthService: process.env.REACT_APP_API_AUTH_SERVICE || '',
  apiBusinessesService: process.env.REACT_APP_API_BUSINESSES_SERVICE || '',
  apiDefaultValuesService:
    process.env.REACT_APP_API_DEFAULT_VALUES_SERVICE || '',
  apiDocumentSummariesService:
    process.env.REACT_APP_API_BUSINESS_SUMMARIES_SERVICE || '',
  apiBusinessUsersService:
    process.env.REACT_APP_API_BUSINESS_USERS_SERVICE || '',
  apiContactsService: process.env.REACT_APP_API_CONTACTS_SERVICE || '',
  apiContactsWithAmountsService:
    process.env.REACT_APP_API_CONTACTS_WITH_AMOUNTS_SERVICE || '',

  apiDocumentBookkeeper:
    process.env.REACT_APP_API_DOCUMENT_BOOKKEEPER_SERVICE || '',
  apiDocumentsService: process.env.REACT_APP_API_DOCUMENTS_SERVICE || '',
  apiDocumentsSendService:
    process.env.REACT_APP_API_DOCUMENTS_SEND_SERVICE || '',
  apiDocumentDraftsService: process.env.REACT_APP_API_DOCUMENT_DRAFTS || '',
  apiDocumentSeriesService: process.env.REACT_APP_API_DOCUMENT_SERIES || '',
  apiDocumentScanService: process.env.REACT_APP_API_DOCUMENT_SCAN || '',
  apiDocumentsExpensesService:
    process.env.REACT_APP_API_DOCUMENT_EXPENSES_SERVICE || '',
  apiDocumentTrackingService:
    process.env.REACT_APP_API_DOCUMENT_TRACKING_SERVICE || '',
  apiDocumentsRecurrentService:
    process.env.REACT_APP_API_RECURRENT_DOCUMENTS_SERVICE || '',
  apiDocumentsPendingService:
    process.env.REACT_APP_API_DOCUMENT_PENDING_SERVICE || '',
  apiIntegrationApiConfig: process.env.REACT_APP_API_INTEGRATIONS_API || '',
  apiIntegrationApiCredentials:
    process.env.REACT_APP_API_INTEGRATIONS_API_CREDENTIALS || '',
  apiIntegrationShopifyConfigWebhooks:
    process.env.REACT_APP_API_INTEGRATIONS_SHOPIFY_WEBHOOKS || '',
  apiIntegrationShopifyConfig:
    process.env.REACT_APP_API_INTEGRATIONS_SHOPIFY || '',
  apiDocumentDownloadZipService:
    process.env.REACT_APP_API_DOCUMENT_DOWNLOAD_ZIP || '',
  apiPaymentMethodsService: process.env.REACT_APP_API_PAYMENT_METHODS || '',
  apiPaymentsService: process.env.REACT_APP_API_PAYMENTS || '',
  apiAccountingAccountsService:
    process.env.REACT_APP_API_ACCOUNTING_ACCOUNTS || '',
  apiDocumentFaceService: process.env.REACT_APP_API_FACE_SERVICE || '',
  apiPrinterService: process.env.REACT_APP_API_PRINTER_SERVICE || '',
  apiLeadsService: process.env.REACT_APP_API_LEADS_SERVICE || '',
  apiProductService: process.env.REACT_APP_API_PRODUCT_SERVICE || '',
  apiProductsService: process.env.REACT_APP_API_PRODUCTS_SERVICE || '',
  apiQuotes: process.env.REACT_APP_API_QUOTES_SERVICE || '',
  apiDeliveryNotes: process.env.REACT_APP_API_DELIVERY_NOTES_SERVICE || '',
  apiProformas: process.env.REACT_APP_API_PROFORMAS_SERVICE || '',
  apiRemittancesService: process.env.REACT_APP_API_REMITTANCES_SERVICE || '',
  apiRemittancesDocumentsService:
    process.env.REACT_APP_API_REMITTANCES_DOCUMENTS_SERVICE || '',
  apiStripeService: process.env.REACT_APP_API_STRIPE_SERVICE || '',
  apiCouponsService: process.env.REACT_APP_API_COUPONS_SERVICE || '',
  apiSubscriptionProductsService:
    process.env.REACT_APP_API_SUBSCRIPTION_PRODUCTS_SERVICE || '',
  apiImportUsersInvitations:
    process.env.REACT_APP_API_IMPORT_USERS_INVITATIONS || '',
  apiImportContacts: process.env.REACT_APP_API_IMPORT_CONTACTS || '',
  apiImportProducts: process.env.REACT_APP_API_IMPORT_PRODUCTS || '',
  apiSubscriptionsService:
    process.env.REACT_APP_API_SUBSCRIPTIONS_SERVICE || '',
  apiTasksService: process.env.REACT_APP_API_TASKS_SERVICE || '',
  apiTemplatesConfig: process.env.REACT_APP_API_TEMPLATES_CONFIG || '',
  apiUsersService: process.env.REACT_APP_API_USERS_SERVICE || '',
  apiDocumentCheckPendings:
    process.env.REACT_APP_API_DOCUMENT_CHECK_PENDINGS || '',
  apiUserInvitationsService:
    process.env.REACT_APP_API_USER_INVITATIONS_SERVICE || '',
  apiPaymentRemindersService: process.env.REACT_APP_API_PAYMENT_REMINDERS || '',
  apiUserNotificationsService:
    process.env.REACT_APP_API_USER_NOTIFICATIONS_SERVICE || '',
  apiShopifyService: process.env.REACT_APP_API_SHOPIFY_SERVICE || '',
  apiPaymentRemindersDocumentService:
    process.env.REACT_APP_API_PAYMENT_REMINDER_DOCUMENT || '',
  environment: process.env.REACT_APP_ENVIRONMENT || '',
  name: process.env.REACT_APP_NAME || '',
  segmentApiKey: process.env.REACT_APP_SEGMENT_API_KEY || '',
  stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY || '',
  configCatSDKKey: process.env.REACT_APP_CONFIGCAT_SDK_KEY || '',
  zendeskApiKey: process.env.REACT_APP_ZENDESK_KEY || '',
  version: process.env.REACT_APP_VERSION || '',
  enableTestNif: process.env.REACT_APP_ENABLE_TEST_NIF || '',
  enableAnalytics: process.env.REACT_APP_ENABLE_ANALYTICS || '',
  smartlookKey: process.env.REACT_APP_SMART_LOOK_KEY || '',
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || '',
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY || '',
  recaptchaInvisibleKey: process.env.REACT_APP_RECAPTCHA_INVISIBLE_KEY || '',
  turnstileKey: process.env.REACT_APP_TURNSTILE_KEY || '',
  turnstileInvisibleKey: process.env.REACT_APP_TURNSTILE_INVISIBLE_KEY || '',
  captchaProvider:
    (process.env.REACT_APP_CAPTCHA_PROVIDER as CaptchaProvider) || '',
  zipPollingInterval: process.env.REACT_APP_ZIP_POLLING_INTERVAL || '1000',
  zipPollingTimeout: process.env.REACT_APP_ZIP_POLLING_TIMEOUT || '30000',
};

export default defaultConfig;
