import useTranslations from 'modules/I18n/hooks/useTranslations';
import { FormikField } from 'modules/Ui';
import MaskedFormikField from 'modules/Ui/Formik/MaskedFormikField';

import messages from '../messages';
import SubFormBox from './SubFormBox';

interface Props {
  isEditing: boolean;
  forContact?: boolean;
}

const TransferSubForm: React.FC<Props> = ({ isEditing = false }) => {
  const { t } = useTranslations();
  return (
    <SubFormBox
      checkColumnEnd={{ sm: '3' }}
      checkColumnStart={{ sm: '1' }}
      columnGap={{ sm: '24px' }}
      display={{ sm: 'grid' }}
      gridTemplateColumns={{ sm: '2fr 1fr' }}
      isEditing={isEditing}
    >
      <FormikField
        id="data.alias"
        helpText={t(messages.transferHelp)}
        label={t(messages.bank_account_alias)}
        data-testid="alias-input"
      />
      <MaskedFormikField
        autoTrim
        data-testid="iban-input"
        gridColumnStart={{ sm: '1' }}
        id="data.IBAN"
        label={t(messages.bank_account_iban)}
        mask="AA11 1111 1111 1111 1111 1111"
        placeholder={t(messages.bank_account_iban_placeholder)}
        placeholderChar=" "
        required
      />
      <FormikField
        id="data.swiftCode"
        label={t(messages.bank_account_bic)}
        data-testid="swiftCode-input"
      />
    </SubFormBox>
  );
};

export default TransferSubForm;
