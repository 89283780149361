import { useSelector } from 'react-redux';

import differenceInDays from 'date-fns/differenceInDays';

import { useIsBookkeeping } from 'modules/Bookkeeper/hooks/useIsBookkeeperUser';
import { shouldFill140Document } from 'modules/Documents/components/TwoStepsForm/FirstStep/components/TicketBaiOptions/helpers';
import {
  DigitalDocumentStatus,
  Document,
  DocumentStatus,
  DocumentType,
} from 'modules/Documents/models/document';

import {
  hasElectronicDocumentProvider,
  shouldSendElectronicDocument,
} from '../businessUtils';
import { selectCurrentBusiness } from '../Current/selectors';
import { BaiProviders } from '../models/business';

const useElectronicDocumentProvider = () => {
  const business = useSelector(selectCurrentBusiness);
  const hasElectronicProvider = hasElectronicDocumentProvider(business);
  const provider = business.electronicDocumentProvider;
  const canSendElectronicDocument = shouldSendElectronicDocument(business);
  const isBookkeeping = useIsBookkeeping();
  const shouldFill140 =
    isBookkeeping ||
    shouldFill140Document(
      business.vatNumber,
      business.electronicDocumentProvider
    );

  const canVoidDocument = (document: Document) => {
    const isPresented =
      document.digitalDocument?.status === DigitalDocumentStatus.PRESENTED;
    const isPresentedWithErrors =
      document.digitalDocument?.status ===
      DigitalDocumentStatus.PRESENTED_WITH_ERRORS;

    const canVoid = canSendElectronicDocument
      ? isPresented || isPresentedWithErrors
      : hasElectronicProvider;

    const isIssuedWithin30Days = document.issuedDate
      ? differenceInDays(new Date(), new Date(document.issuedDate)) <= 30
      : false;

    return (
      canVoid &&
      document.status === DocumentStatus.ISSUED &&
      document.documentType !== DocumentType.EXPENSE &&
      isIssuedWithin30Days
    );
  };

  return {
    canSendElectronicDocument,
    canVoidDocument,
    hasElectronicProvider,
    shouldFill140,
    isAlava: provider === BaiProviders.VI,
    isBizkaia: provider === BaiProviders.BI,
    isGipuzkoa: provider === BaiProviders.SS,
    provider,
  };
};

export default useElectronicDocumentProvider;
